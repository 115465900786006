import React, { useState, useContext } from 'react'
import { Drawer, User, Grid, Text, Button } from '@geist-ui/react'
import { Link, useHistory } from 'react-router-dom'
import { authContext } from '../../Contexts/authProvider'
import { Menu } from '@geist-ui/react-icons'

const Navbar = () => {
  const history = useHistory()
  const { profile, loading } = useContext(authContext)
  const [state, setState] = useState(false)

  return (
    <>
      <Button
        auto
        onClick={() => setState(true)}
        className="mt-2"
        icon={<Menu size={36} />}
        type="abort">
        <Text className="pointer" h4 b>
          <span className="blue">POLL</span>POLITIC
        </Text>
      </Button>
      <Drawer
        id="mobileNav"
        visible={state}
        onClose={() => setState(false)}
        placement="left">
        <Drawer.Content>
          <Grid justify="center" xs={24}>
            <Text className="pointer mb-4" h4 b>
              <span className="blue">POLL</span>
              <span className="white">POLITIC</span>
            </Text>
          </Grid>
          <Grid.Container
            style={{ paddingTop: '15px', zIndex: '9', position: 'absolute' }}
            gap={2}>
            <Grid alignItems="column">
              <Link to="/">
                <Text style={{ marginTop: 0 }}>Home</Text>
              </Link>
              <Link to="/about">
                <Text style={{ marginTop: 0 }}>About</Text>
              </Link>
              <Link to="/officialpolls">
                <Text style={{ marginTop: 0 }}>Official Polls</Text>
              </Link>
              <Link to="/communitypolls">
                <Text style={{ marginTop: 0 }}>Community Polls</Text>
              </Link>
            </Grid>
            <Grid alignItems="column">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {profile ? (
                    <Link to={`/profile/${profile._id}`}>
                      <User src={profile.profileImage} name={profile.displayName} />
                    </Link>
                  ) : (
                    <Link to="/signin">
                      <Button type="success" scale={0.7}>Sign In</Button>
                    </Link>
                  )}
                </>
              )}
            </Grid>
          </Grid.Container>
        </Drawer.Content>
      </Drawer>
    </>
  )
}

export default Navbar
