import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'

const CookieConsentDialog = () => {
  // Cookies.set("test", "nice")
  return (
    <CookieConsent
      cookieName="allowPollPoliticConsent"
      buttonStyle={{
        color: '#4e503b',
        fontWeight: '700',
        background: '#fff',
        borderRadius: '5px',
        padding: '10px 20px',
        fontSize: 'calc(0.875 * 16px)',
      }}
      buttonText="Accept Cookies">
      PollPolitic uses cookies to enhance your browsing experience and to analyze site
      traffic. By closing this banner, you consent to our use of cookies. Read more about
      how we use cookies clicking our <Link to="/privacypolicy">Privacy Policy</Link>.
    </CookieConsent>
  )
}
export default CookieConsentDialog
