import { POLLAXY_API_URL as API_URL } from '../Constants/actionTypes'
import { handleResponse, authHeader } from '../Helpers'

export const postService = {
  getAllPosts,
  getPost,
  addPost,
  updatePost,
  removePost,
  deletePost,
  likePost,
  unlikePost,
}

function getAllPosts(page, q) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(
    `${API_URL}/posts?page=${page ? page : 1}${q ? `&q=${q}` : ''}`,
    requestOptions,
  )
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function getPost(id) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(`${API_URL}/posts/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function addPost(pollId, payload) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    credentials: 'include',
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/posts/${pollId}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function updatePost(pollId, id, payload) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/posts/${pollId}/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function removePost(pollId, id) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
  }
  return fetch(`${API_URL}/posts/${pollId}/${id}/remove`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function deletePost(pollId, id) {
  const requestOptions = {
    method: 'DELETE',
    credentials: 'include',
    headers: authHeader(),
  }
  return fetch(`${API_URL}/posts/${pollId}/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function likePost(id, pollId) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: authHeader(),
  }
  return fetch(`${API_URL}/posts/${pollId}/${id}/like`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function unlikePost(id, pollId) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: authHeader(),
  }
  return fetch(`${API_URL}/posts/${pollId}/${id}/unlike`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}
