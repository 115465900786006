let csrf;

export function csrfToken(token) {
  if (token) {
    csrf = token;
    return csrf;
  } else {
    return csrf;
  }
}
