import React, { useContext } from 'react'
import { Button } from '@geist-ui/react'
import { authService } from '../../Services'
import { useHistory } from 'react-router-dom'
import { authContext } from '../../Contexts/authProvider'
import Toast from '../Common/Toast'
import { Facebook } from '@geist-ui/react-icons'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const clientId =
  '436079511560306'

function FacebookForm() {
  let history = useHistory()
  const { setAuthData } = useContext(authContext)

  const onSuccess = res => {
    authService
      .facebook()
      .then(res => {
        setAuthData(res)
        Toast('Welcome back to PollPoltiic')
        history.push('/')
      })
      .catch(res => {
        Toast('Something Went Wrong!', res, 'error')
      })
  }
  const onFailure = res => {
    Toast(res.error)
  }

  return (
    <FacebookLogin
      appId={clientId}
      fields="name,email,picture"
      render={renderProps => (
        <Button
          className="mb-4 mx-2 mt-2"
          type="secondary"
          icon={<Facebook />}
          ghost
          auto
          onClick={renderProps.onClick}>
        </Button>
      )}
      callback={onSuccess}
      onFailure={onFailure}
      cookie={'single_host_origin'}
      buttonText="Login"
    />
  )
}

export default FacebookForm
