import React from 'react'
import { Field } from 'formik'
import { Input } from '@geist-ui/react'
import { Search } from '@geist-ui/react-icons'

function FormSearch(props) {
  const { label, name, placeholder, updateQuery, ...rest } = props
  return (
    <div>
      <Input
        icon={<Search />}
        id={name}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            updateQuery(e.target.value);
          }
        }}
        placeholder="Search..."
      />
    </div>
  )
}

export default FormSearch
