import React from 'react'
import { Text, Grid } from '@geist-ui/react'
import Layout from './Layout/Layout'
import HelmetContainer from '../Components/Containers/HelmetContainer'

const NotFound = () => {
  return (
    <Layout>
      <HelmetContainer title="Not Found" />
      <Grid flexWrap="wrap-reverse" className="text-center mt-5" alignItems="center">
        <Text h2>Page Not Found</Text>
        <Text mb={6}>It appears you've gotten yourself lost...</Text>
      </Grid>
    </Layout>
  )
}

export default NotFound
