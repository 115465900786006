import React, { useState } from 'react'
import { communityPollService } from '../Services'
import PollContainer from '../Components/Containers/PollContainer'
import { PARAM_TYPES } from '../Constants'
import HelmetContainer from '../Components/Containers/HelmetContainer'
import InfiniteScrollContainer from '../Components/Containers/InfiniteScrollContainer'
import Layout from './Layout/Layout'
import { uppercase } from '../Helpers'

const CommunityPolls = () => {
  const [pageControl, setPageControl] = useState({
    page: 1,
    results: [],
    query:'',
    param: '',
    availParams: PARAM_TYPES.Polls,
    totalHits: 0,
  })

  const asyncCall = () => {
    return communityPollService.getCommunityPolls(pageControl.param, pageControl.page, pageControl.query)
  }

  return (
    <Layout>
      <InfiniteScrollContainer
        asyncCall={asyncCall}
        name="Community"
        pageControl={pageControl}
        setPageControl={setPageControl}>
        <HelmetContainer title={uppercase('Communiy Polls')} />
        {pageControl.results.map(result => {
          return <PollContainer type="community" key={result._id} data={result} />
        })}
      </InfiniteScrollContainer>
    </Layout>
  )
}

export default CommunityPolls
