import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { authContext } from "../Contexts/authProvider";
import Loader from "../Components/Common/Loader";

export const PrivateRoute = ({ component: Component, render, ...rest }) => {
  const { profile, loading } = useContext(authContext);
  if(loading) return <Loader/>
  const renderContent = props => {

    if (!profile) {
      return (
        <Redirect to="/signin" />
      )
    }
    return (typeof render === 'function') ? render(props) : <Component {...props} />
  }
  
  return (
    <Route {...rest} render={renderContent} />
  );
};


