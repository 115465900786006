import React, { useState, useEffect } from 'react'
import Layout from './Layout/Layout'
import { Grid, Text } from '@geist-ui/react'
import { postService } from '../Services'
import Loader from '../Components/Common/Loader'
import PostContainer from '../Components/Containers/PostContainer'
import { useHistory } from 'react-router-dom'
import Toast from '../Components/Common/Toast'
import HelmetContainer from '../Components/Containers/HelmetContainer'

const Post = props => {
  const [loading, setLoading] = useState(true)
  const [post, setPost] = useState(null)
  const [error, setError] = useState(null)
  let history = useHistory()
  const { match } = props

  const asyncCall = () => {
    return postService.getPost(match.params.id)
  }

  const handleSuccess = response => {
    setPost(response)
    setLoading(false)
  }

  const handleError = error => {
    setError(error)
    setLoading(false)
  }

  function fetchPost() {
    return asyncCall().then(handleSuccess).catch(handleError)
  }

  const toDelete = (pollId, id) => {
    pollId = post.poll
    postService
      .removePost(pollId, id)
      .then(() => {
        history.push(`/polls/${pollId}`)
        Toast('Post Deleted')
      })
      .catch(res => {
        Toast(res, 'error-toast')
      })
  }

  const toEdit = (pollId, id, value) => {
    pollId = post.poll
    postService
      .updatePost(pollId, id, value)
      .then(res => {
        setPost(prevState => ({
          ...prevState,
          body: res,
        }))
        Toast('Post Updated!')
      })
      .catch(res => {
        Toast(res, 'error-toast')
      })
  }

  useEffect(() => {
    fetchPost()
  }, [match])

  if (loading) return <Loader />
  return (
    <Layout>
      <div className="mt-4">
        {post ? (
          <>
            <HelmetContainer title={post.body} />

            <PostContainer
              toEdit={toEdit}
              prevValue={post.body}
              toDelete={toDelete}
              single={false}
              data={post}
            />
          </>
        ) : (
          <Grid className="mt-4 text-center" xs={24}>
            <Text h3>{error}</Text>
          </Grid>
        )}
      </div>
    </Layout>
  )
}
export default Post
