import React from 'react'
import { Field } from 'formik'

function FormVote(props) {
  const { label, name, placeholder, ...rest } = props
  return (
    <Field name={name}>
      {({ field }) => {
        return (
          <div className="custom-radios">
            <input type="radio" id={name} className="color-3" {...rest} {...field} />
            <label for="color-3">
              <span>
                <img
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                  alt="Checked Icon"
                />
              </span>
              <strong>{name}</strong>
            </label>
          </div>
        )
      }}
    </Field>
  )
}

export default FormVote
