import React, { useContext, useState } from 'react'
import { officialPollService, communityPollService } from '../../Services'
import { authContext } from '../../Contexts/authProvider'
import Toast from '../Common/Toast'
import { Button } from '@geist-ui/react'
import { Heart, HeartFill } from '@geist-ui/react-icons'

const Favorite = ({ data, type }) => {
  const { profile } = useContext(authContext)
  const [loading, setLoading] = useState(false)
  const [currentFavorites, setCurrentFavorites] = useState(data.favorited)

  async function favorite() {
    setLoading(true)
    if (type === 'Official') {
      await officialPollService
        .favoriteOfficialPoll(data._id, data.poll)
        .then(() => {
          setCurrentFavorites([profile._id, ...currentFavorites])
          setLoading(false)
        })
        .catch(res => {
          setLoading(false)
          Toast(res)
        })
    } else if (type === 'Community') {
      await communityPollService
        .favoriteCommunityPoll(data._id, data.poll)
        .then(() => {
          setCurrentFavorites([profile._id, ...currentFavorites])
          setLoading(false)
        })
        .catch(res => {
          setLoading(false)
          Toast(res)
        })
    }
  }
  async function unfavorite() {
    setLoading(true)
    if (type === 'Official') {
      await officialPollService
        .unfavoriteOfficialPoll(data._id, data.poll)
        .then(() => {
          setCurrentFavorites(currentFavorites.filter(item => item !== profile._id))
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          Toast('Unable to unfavorite poll!')
        })
    } else if (type === 'Community') {
      await communityPollService
        .unfavoriteCommunityPoll(data._id, data.poll)
        .then(() => {
          setCurrentFavorites(currentFavorites.filter(item => item !== profile._id))
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
          Toast('Unable to unfavorite poll!')
        })
    }
  }
  return (
    <>
      {currentFavorites.find(fav => fav.toString() === profile._id.toString()) ? (
        <Button
          iconRight={<HeartFill color="#0070F3" />}
          loading={loading}
          onClick={() => unfavorite()}
          auto
          pr={0.4} 
        />
      ) : (
        <Button pr={0.4}  iconRight={<Heart />} loading={loading} onClick={() => favorite()} auto />
      )}
    </>
  )
}

export default Favorite
