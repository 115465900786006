import React, { useState, useEffect } from 'react'
import { LoadingState, ErrorState, EmptyState } from '../Results'
import useStatus from '../../Hooks/useStatus'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Grid, Text, Card } from '@geist-ui/react'
import { uppercase } from '../../Helpers'
import FormSearch from '../Form/FormSearch'
import { Link } from 'react-router-dom'
import BackToTop from '../Common/BackToTop'
import { useTheme } from '@geist-ui/react'

const InfiniteScrollContainer = props => {
  const { children, pageControl, setPageControl, name, asyncCall, posts } = props
  const [error, setError] = useState(null)
  const { palette } = useTheme()
  const { Status, setStatus } = useStatus('success')
  const [initialized, setInitialized] = useState(false)

  const handleSuccess = response => {
    if (!initialized) {
      setPageControl({
        ...pageControl,
        page: pageControl.page + 1,
        totalHits: response.totalDocuments,
        results: response.data,
      })
      response.data.length === 0 ? setStatus('empty') : setStatus('success')
      setInitialized(true)
    } else {
      setPageControl(oldArr => ({
        ...oldArr,
        page: pageControl.page + 1,
        totalHits: response.totalDocuments,
        results: [...oldArr.results, ...response.data],
      }))
    }
  }

  const handleError = error => {
    setError(error)
    setStatus('error')
  }

  const updateQuery = e => {
    setPageControl({ ...pageControl, query: e, page: 1, results: [] })
  }

  const handleParam = prop => {
    if (prop !== pageControl.param) {
      setPageControl({ ...pageControl, param: prop, page: 1, results: [] })
    }
  }

  function fetchResults() {
    if (!initialized) {
      setStatus('loading')
    }
    return asyncCall().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    if (!initialized) {
      setPageControl({ ...pageControl, page: 1 })
      fetchResults()
      return () => {
        setPageControl({ ...pageControl, page: 1, results: [] })
        setStatus('loading')
      }
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      setPageControl({ ...pageControl, page: 1, results: [] })
      fetchResults()
    }
  }, [pageControl.view, pageControl.param, pageControl.init, pageControl.query])

  return (
    <>
      {name && (
        <>
          <Grid align="center" md={0} sm={24} xs={24}>
            <Text className="text-center subHeader" h2>
              {uppercase(name)}
            </Text>
          </Grid>
          <Grid align="center" md={24} sm={0} xs={0}>
            <Text className="text-center mt-4 subHeader" h2>
              .:: {uppercase(name)} ::.
            </Text>
          </Grid>
        </>
      )}
      {name ? (
        <Grid.Container>
          <Grid justify="center" className="text-center" gap={2} xs={24}>
            {pageControl.availParams.map(p => (
              <Text
                key={p.param}
                onClick={() => handleParam(p.param)}
                className={`${
                  pageControl.param === p.param ? 'tabActive' : null
                } pointer mr-4`}>
                {p.name}
              </Text>
            ))}
            {name === 'Community' ? (
              <Link to="/createpoll">
                <Text>Create Poll</Text>
              </Link>
            ) : null}
          </Grid>
          <Grid justify="center" xs={24}>
            <FormSearch updateQuery={updateQuery} />
          </Grid>
        </Grid.Container>
      ) : posts ? (
        <Card
          className="mb-2"
          shadow
          style={{ position: 'relative', zIndex: 2, background: palette.accents_1 }}
          align="left">
          <Text>
            <span className="bold">Sort By: </span>
            {pageControl.availParams.map(p => (
              <span
                key={p.param}
                onClick={() => handleParam(p.param)}
                className={`${
                  pageControl.param === p.param ? 'tabActive' : null
                } pointer mr-2`}>
                {p.name}
              </span>
            ))}
          </Text>
        </Card>
      ) : null}
            <BackToTop />

      <InfiniteScroll
        next={fetchResults}
        dataLength={pageControl.results.length}
        hasMore={pageControl.totalHits > pageControl.results.length}
        threshold={100}
        loader={<Spinner />}
        style={{ minHeight: '80vh', width: '100%', position: 'relative', zIndex: 2 }}>
        <Status
          loading={<LoadingState />}
          empty={<EmptyState />}
          error={<ErrorState error={error} retry={fetchResults} />}
          success={children}
        />
      </InfiniteScroll>
    </>
  )
}

export default InfiniteScrollContainer
