import React from 'react'
import { Grid, Spinner } from '@geist-ui/react'

const LoadingState = () => {
  return (
    <Grid.Container className="mt-4">
      <Grid justify="center" className="text-center" gap={2} xs={24}>
        <Spinner size="large" />
      </Grid>
    </Grid.Container>
  )
}

export { LoadingState }
