import { POLLAXY_API_URL as API_URL } from "../Constants/actionTypes";
import { handleResponse, authHeader } from "../Helpers";
import { ParamControl } from '../Reducers';

export const communityPollService = {
  getCommunityPolls,
  getCommunityPollPosts,
  addCommunityPoll,
  deleteCommunityPoll,
  removeCommunityPoll,
  castCommunityVote,
  favoriteCommunityPoll,
  unfavoriteCommunityPoll,
  acceptPoll,
  rejectPoll,
  openPoll,
  toggleCloseCommunityPoll
};


function getCommunityPolls(param, page, q) {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };
    return fetch(`${API_URL}/polls/community?page=${page ? page: 1}${param ? `&${ParamControl(param)}` : ''}${q ? `&q=${q}`: ''}`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }

  function getCommunityPollPosts(pollId, page, q) {
    const requestOptions = {
      method: 'GET',
      credentials: 'include',
    }
    return fetch(
      `${API_URL}/polls/community/${pollId}/posts?page=${page ? page : 1}${q ? `&q=${q}` : ''}`,
      requestOptions,
    )
      .then(handleResponse)
      .then(res => {
        return res
      })
  }
  
  function addCommunityPoll(payload) {
    console.log(payload)
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: authHeader(),
      body: JSON.stringify(payload),
    };
    return fetch(`${API_URL}/polls/community`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
  function removeCommunityPoll(id) {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: authHeader(),
    };
    return fetch(`${API_URL}/polls/community/${id}/remove`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
  function deleteCommunityPoll(id) {
    const requestOptions = {
      method: "DELETE",
      credentials: "include",
      headers: authHeader(),
    };
    return fetch(`${API_URL}/polls/community/${id}`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
  function castCommunityVote(id, payload) {
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: authHeader(),
      body: JSON.stringify(payload)
    };
    return fetch(`${API_URL}/polls/community/${id}/vote`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
  function favoriteCommunityPoll(id) {
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: authHeader(),
    };
    return fetch(`${API_URL}/polls/community/${id}/favorite`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
  function unfavoriteCommunityPoll(id) {
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: authHeader(),
    };
    return fetch(`${API_URL}/polls/community/${id}/unfavorite`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  
  function toggleCloseCommunityPoll(id) {
    const requestOptions = {
      method: "PUT",
      credentials: "include",
      headers: authHeader(),
    };
    return fetch(`${API_URL}/polls/community/${id}/close`, requestOptions)
      .then(handleResponse)
      .then(res => {
        return res;
      });
  }
  

function acceptPoll(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/community/${id}/accept`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function rejectPoll(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/community/${id}/reject`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function openPoll(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/community/${id}/open`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}