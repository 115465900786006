import { POLLAXY_API_URL as API_URL } from '../Constants/actionTypes'
import { handleResponse, authHeader } from '../Helpers'

export const sharedPollService = {
  getPoll,
  addVote,
}

function addVote(id, payload) {
  const requestOptions = {
    method: 'PUT',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/polls/${id}/vote`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function getPoll(id) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(`${API_URL}/polls/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}
