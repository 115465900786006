import React from 'react'
import { User, Grid } from '@geist-ui/react'
import { useHistory } from 'react-router-dom'

const UserContainer = ({ user }) => {
  let history = useHistory()
  const handler = () => {
    history.push(`/profile/${user._id}`)
  }
  return (
    <Grid className="pointer" onClick={handler} align="left" mb={1} mt={0.5} xs={20}>
      <User src={user.profileImage} name={user.displayName}>
        {user.state} | {user.country} | {user.politicalAlignment}
      </User>
    </Grid>
  )
}

export default UserContainer
