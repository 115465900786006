import React, { useContext } from 'react'
import { Switch, Route } from 'react-router-dom'
import FormProvider from './Contexts/FormProvider'
import { ToastContainer } from 'react-toastify'
import { GeistProvider, CssBaseline } from '@geist-ui/react'
import CookieConsentDialog from './Components/CookieConsentDialog/CookieConsentDialog'
import { authContext } from './Contexts/authProvider'
import { configContext } from './Contexts/configProvider'
import Loader from './Components/Common/Loader'
import { PrivateRoute, PublicRoute } from './Helpers'
import Home from './Pages/Home'
import Form from './Pages/Form'
import OfficialPolls from './Pages/OfficialPolls'
import CommunityPolls from './Pages/CommunityPolls'
import Poll from './Pages/Poll'
import Post from './Pages/Post'
import About from './Pages/About'
import Profile from './Pages/Profile'
import NotFound from './Pages/NotFound'
import TermsOfService from './Pages/TermsOfService'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import Maintenance from './Pages/Maintenance'
import { PollPoliticDark } from './Themes/DarkTheme'
import './index.css'

const App = () => {
  const { loading, profile } = useContext(authContext)
  const { maintenance } = useContext(configContext)

  if (loading) return <Loader />
  if (maintenance)
    return (
      <GeistProvider themeType="light">
        <CssBaseline />
        <Maintenance />
      </GeistProvider>
    )
  return (
    <GeistProvider
      themes={[PollPoliticDark]}
      themeType={!profile ? 'light' : profile.darkMode ? 'pollPoliticDark' : 'light'}>
      <CssBaseline />
      <FormProvider>
        <Route
          render={({ location }) => {
            return (
              <Switch location={location}>
                <PublicRoute exact path="/" component={Home} />
                <PublicRoute exact path="/about" component={About} />
                <PublicRoute
                  path="/signin"
                  exact
                  render={props => <Form route="login" {...props} />}
                />
                <PublicRoute
                  path="/signup"
                  exact
                  render={props => <Form route="register" {...props} />}
                />
                <PublicRoute
                  path="/resetpassword"
                  exact
                  render={props => <Form route="resetpassword" {...props} />}
                />
                <PublicRoute
                  path="/officialpolls"
                  exact
                  render={props => <OfficialPolls {...props} />}
                />
                <PublicRoute
                  path="/communitypolls"
                  exact
                  render={props => <CommunityPolls {...props} />}
                />
                <PublicRoute
                  path="/polls/:id"
                  exact
                  render={props => <Poll {...props} />}
                />
                <PublicRoute
                  path="/newpassword/:id"
                  exact
                  render={props => <Form route="newpassword" {...props} />}
                />
                <PublicRoute
                  path="/verify/:id"
                  exact
                  render={props => <Form route="verify" {...props} />}
                />
                <PrivateRoute
                  path="/posts/:id"
                  exact
                  render={props => <Post {...props} />}
                />
                <PrivateRoute
                  path="/createpoll"
                  exact
                  render={props => <Form route="poll" {...props} />}
                />
                <PrivateRoute
                  path="/profile/:id"
                  exact
                  render={props => <Profile {...props} />}
                />
                <PublicRoute exact path="/termsofservice" component={TermsOfService} />
                <PublicRoute exact path="/privacypolicy" component={PrivacyPolicy} />
                <PublicRoute component={NotFound} />
              </Switch>
            )
          }}
        />
        <CookieConsentDialog />
      </FormProvider>
      <ToastContainer />
    </GeistProvider>
  )
}

export default App
