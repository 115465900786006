import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { FormikControl } from "../../Reducers";
import { authContext } from "../../Contexts/authProvider";
import { Button, Text } from "@geist-ui/react";
import { useHistory } from "react-router-dom";
import { authService } from "../../Services";
import HelmetContainer from "../Containers/HelmetContainer";
import Toast from "../Common/Toast";

function NewPasswordForm({ match }) {
  const [submitting, setSubmitting] = useState(false);
  let history = useHistory();
  const { profile } = useContext(authContext);

  if (profile) {
    history.push("/");
  }

  const initialValues = {
    password: "",
    password2: "",
  };

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, "Must be atleast 6 characters")
      .max(50, "Must be at the most 50 characters")
      .required("Required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const onSubmit = (values) => {
    authService
      .resetPassword(match.params.id, values)
      .then(() => {
        setSubmitting(false);
        Toast("Reset Success!", "Please log in", "success");
        history.push("/signin");
      })
      .catch((res) => {
        setSubmitting(false);
        Toast("Something Went Wrong!", res, "error");
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {() => {
        return (
          <div className="mb-4 px-4">
            <HelmetContainer title="New Password" />
            <Text className="my-4" h2>
              New Password
            </Text>
            <Form>
              <div>
                <FormikControl
                  control="password"
                  label="Password"
                  name="password"
                  placeholder=" New Password"
                />
              </div>
              <div className="mt-2">
                <FormikControl
                  control="password"
                  label="Confirm Password"
                  name="password2"
                  placeholder="Confirm New Password"
                />
              </div>
              <Button
                w="100%"
                className="mt-2"
                loading={submitting ? true : false}
              >
                Reset Password
              </Button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}

export default NewPasswordForm;
