import React, { useState } from 'react'
import { communityPollService, officialPollService, postService } from '../Services'
import PostContainer from '../Components/Containers/PostContainer'
import InfiniteScrollContainer from '../Components/Containers/InfiniteScrollContainer'
import { Text, Card } from '@geist-ui/react'
import { FormControl } from '../Reducers'
import Toast from '../Components/Common/Toast'
import { PARAM_TYPES } from '../Constants'
import { uppercase } from '../Helpers'

const Posts = ({ id, length, single, type }) => {
  const [lengthCheck, setLengthCheck] = useState(length)
  const [pageControl, setPageControl] = useState({
    page: 1,
    results: [],
    param: '',
    availParams: PARAM_TYPES.Posts,
    totalHits: 0,
  })

  const asyncCall = () => {
    if (uppercase(type) === 'Official') {
      return officialPollService.getOfficialPollPosts(
        id,
        pageControl.page,
        pageControl.param,
      )
    } else if (uppercase(type) === 'Community') {
      return communityPollService.getCommunityPollPosts(
        id,
        pageControl.page,
        pageControl.param,
      )
    }
  }

  const postAdded = post => {
    setLengthCheck(lengthCheck + 1)
    setPageControl({
      ...pageControl,
      totalHits: pageControl.totalHits + 1,
      results: [post, ...pageControl.results],
    })
  }

  const toDelete = (pollId, id) => {
    postService
      .removePost(pollId, id)
      .then(() => {
        setPageControl({
          ...pageControl,
          totalHits: pageControl.totalHits - 1,
          results: pageControl.results.filter(item => item._id !== id),
        })
        Toast('Post Deleted')
      })
      .catch(res => {
        Toast(res, 'error-toast')
      })
  }

  const toEdit = (pollId, id, value) => {
    postService
      .updatePost(pollId, id, value)
      .then(res => {
        const newArr = pageControl.results
        newArr.find(item => item._id === id).body = res
        setPageControl({
          ...pageControl,
          results: newArr,
        })
        Toast('Post Updated!')
      })
      .catch(res => {
        Toast(res, 'error-toast')
      })
  }

  return (
    <>
      <FormControl postAdded={postAdded} id={id} control="post" />
      {lengthCheck ? (
        <InfiniteScrollContainer
          asyncCall={asyncCall}
          posts={true}
          pageControl={pageControl}
          setPageControl={setPageControl}>
          {pageControl.results.map(result => {
            return (
              <PostContainer
                toDelete={toDelete}
                single={single}
                key={result._id}
                data={result}
                toEdit={toEdit}
                prevValue={result.body}
              />
            )
          })}
        </InfiniteScrollContainer>
      ) : (
        <Card
          shadow
          className="mb-4"
          style={{ position: 'relative', zIndex: 2 }}
          align="left">
          <Text className="text-center" h4>
            Be the First to Post!
          </Text>
        </Card>
      )}
    </>
  )
}
export default Posts
