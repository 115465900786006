import { useState } from 'react';

const useStatus = (initialState) => {
    const [status, setStatus] = useState(initialState);

    const Status = (props) => {
        return props[status] || null;
    }
    return { Status, setStatus };
}

export default useStatus;