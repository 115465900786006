import React, { useContext, useState } from 'react'
import { postService } from '../../Services'
import { authContext } from '../../Contexts/authProvider'
import { ThumbsUp, ThumbsDown } from '@geist-ui/react-icons'
import { Button } from '@geist-ui/react'
import Toast from '../Common/Toast'

const LikeDislike = ({ data }) => {
  const { profile } = useContext(authContext)
  const [loading, setLoading] = useState(false)
  const [currentLikes, setCurrentLikes] = useState(data.likes)
  const [currentDislikes, setCurrentDislikes] = useState(data.dislikes)

  async function likePost() {
    setLoading(true)
    await postService
      .likePost(data._id, data.poll)
      .then(res => {
        setCurrentLikes(res.likes)
        setCurrentDislikes(res.dislikes)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        Toast('Unable to like post!')
      })
  }
  async function unlikePost() {
    setLoading(true)
    await postService
      .unlikePost(data._id, data.poll)
      .then(res => {
        setCurrentLikes(res.likes)
        setCurrentDislikes(res.dislikes)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        Toast('Unable to dislike post!')
      })
  }

  return (
    <>
      {profile && (
        <>
          <Button
            loading={loading}
            icon={
              !currentLikes.find(like => like.user === profile._id) ? (
                <ThumbsUp align="right" />
              ) : (
                <ThumbsUp color="#0070F3" />
              )
            }
            onClick={() => likePost()}
            auto>
            {currentLikes.length}
          </Button>
          <Button
            loading={loading}
            icon={
              !currentDislikes.find(dislike => dislike.user === profile._id) ? (
                <ThumbsDown />
              ) : (
                <ThumbsDown color="#0070F3" />
              )
            }
            onClick={() => unlikePost()}
            auto
            ml={0.5}>
            {currentDislikes.length}
          </Button>
        </>
      )}
    </>
  )
}
export default LikeDislike
