import React, { useState, useEffect } from 'react'
import Layout from './Layout/Layout'
import { Text, User, Grid } from '@geist-ui/react'
import { sharedPollService } from '../Services'
import Loader from '../Components/Common/Loader'
import PollContainer from '../Components/Containers/PollContainer'
import HelmetContainer from '../Components/Containers/HelmetContainer'

const Poll = props => {
  const [loading, setLoading] = useState(true)
  const [poll, setPoll] = useState(null)
  const [error, setError] = useState(null)
  const { match } = props

  const asyncCall = () => {
    return sharedPollService.getPoll(match.params.id)
  }

  const handleSuccess = response => {
    setPoll(response)
    setLoading(false)
  }

  const handleError = error => {
    setError(error)
    setLoading(false)
  }

  function fetchPoll() {
    return asyncCall().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    fetchPoll()
  }, [match])

  if (loading) return <Loader />

  return (
    <Layout>
      {poll ? (
        <>
          <HelmetContainer data={poll} title={poll.name} />
          <Text className="text-center mt-4 subHeader" h2>
            .:: <User className="profileImg" size="large" src={poll.image}></User> ::.
          </Text>
          <Text className="text-center mt-4 mainHeader" h2>
            {poll.name}
          </Text>
          <PollContainer fetchPoll={fetchPoll} type={poll.type} single={true} data={poll} />
        </>
      ) : (
        <Grid className="mt-4 text-center" xs={24}>
          <Text h3>{error}</Text>
        </Grid>
      )}
    </Layout>
  )
}
export default Poll
