import React, { useState } from 'react'
import { Button, Spacer } from '@geist-ui/react'
import { Formik, Form, Field } from 'formik'
import { uppercase } from '../../Helpers';
import { sharedPollService } from '../../Services'
import Toast from '../Common/Toast'

const VoteForm = ({ choices, id, setVoted, fetchPoll }) => {
  const [submitting, setSubmitting] = useState(false)

  const initialValues = {
    choice: '',
  }

  const onSubmit = values => {
    setSubmitting(true)
    sharedPollService
      .addVote(id, values)
      .then(() => {
        fetchPoll()
        setSubmitting(false)
        setVoted(true)
        Toast('Your vote has been cast!')
      })
      .catch(res => {
        setSubmitting(false)
        Toast(res, 'error-toast')
      })
  }

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {formik => {
        return (
          <Form>
            <Spacer />
            {choices.map(val => (
                <div className="custom-radios">
                  <div>
                    <Field
                      type="radio"
                      id={val._id}
                      name="choice"
                      className="color-3"
                      value={val._id}
                    />
                    <label for={val._id}>
                      <span>
                        <img
                          src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/242518/check-icn.svg"
                          alt="Checked Icon"
                        />
                      </span>
                      <strong>{uppercase(val.value)}</strong>
                    </label>
                  </div>
                </div>
            ))}
            <Button
              onClick={() => formik.submitForm()}
              loading={submitting ? true : false}
              className="mt-3"
              type="secondary">
              Vote
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
export default VoteForm
