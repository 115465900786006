import { POLLAXY_API_URL as API_URL } from '../Constants/actionTypes'
import { handleResponse, csrfToken, authHeader } from '../Helpers'

export const authService = {
  login,
  google,
  facebook,
  register,
  resend,
  resetPassword,
  recover,
  logout,
  verify,
  getCsrf,
}

function login(payload) {
  const requestOptions = {
    credentials: 'include',
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/auth/login`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function google(payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify({ payload }),
  }
  return fetch(`${API_URL}/auth/google`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function facebook() {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
  }
  return fetch(`${API_URL}/auth/facebook`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function register(payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/auth/register`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function recover(payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/auth/recover`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function resetPassword(param, payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/auth/reset/${param}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function resend(payload) {
  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: authHeader(),
    body: JSON.stringify(payload),
  }
  return fetch(`${API_URL}/auth/resend`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function logout() {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(`${API_URL}/auth/logout`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function verify(token) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(`${API_URL}/auth/verify/${token}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function getCsrf() {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(`${API_URL}/auth/csrftoken`, requestOptions)
    .then(handleResponse)
    .then(res => {
      csrfToken(res.csrfToken, 'pollpolitic')
      return res
    })
    .catch(() => {
      console.log('Could not retrieve CSRF token')
    })
}
