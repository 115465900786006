export const PARAM_TYPES = {
  Polls: [
    { name: 'New', param: '' },
    { name: 'Hot', param: 'hot' },
    { name: 'Trending', param: 'trending' },
  ],
  Posts: [
    { name: 'New', param: '' },
    { name: 'Top', param: 'top' },
    { name: 'Controversial', param: 'controversial' },
    { name: 'Old', param: 'desc' },
  ],
}
