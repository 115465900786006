import React from 'react'
import { Radio, Text } from '@geist-ui/react'
import { Field } from 'formik'

function FormRadio(props) {
  const { name, options, label } = props
  return (
    <Field className="mb-4" name={name}>
      {({ field, form }) => {
        return (
          <>
            <label>{label}</label>
            <Radio.Group className="mt-2" useRow>
              {options.map(op => {
                return (
                  <Radio
                    name={name}
                    key={op}
                    value={op}
                    onChange={() => form.setFieldValue(name, op)}
                    isChecked={field.value === op}>
                    <Text className="my-0 py-0" font="14px">{op}</Text>
                  </Radio>
                )
              })}
            </Radio.Group>
            <Text className="error">{form.errors[name]}</Text>
          </>
        )
      }}
    </Field>
  )
}

export default FormRadio
