import React from 'react'
import { Text, Grid } from '@geist-ui/react'
import Layout from './Layout/Layout'
import HelmetContainer from '../Components/Containers/HelmetContainer'

const Maintenance = () => {
  return (
    <Layout>
      <HelmetContainer title="Maintenance" />
      <Grid flexWrap="wrap-reverse" className="text-center mt-5" alignItems="center">
        <Text h2>Currently Under Maintenance</Text>
        <Text mb={6}>Please check back soon!</Text>
      </Grid>
    </Layout>
  )
}

export default Maintenance
