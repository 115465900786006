export const ParamControl = (control) => {
    switch(control){
        case 'controversial':
            return 'controversial=true'
        case 'closed':
            return 'closed=true'
        case 'hot':
            return 'hot=true'
        case 'top':
            return 'top=true'
        case 'desc':
            return 'desc=true'
        case 'trending':
            return 'trending=true'
        default:
            return ''
    }
}