import React, { useContext, useState } from 'react'
import { useGoogleLogout } from 'react-google-login'
import { Button, Modal } from '@geist-ui/react'
//import { useHistory } from "react-router-dom";
import { authContext } from '../../Contexts/authProvider'
import Toast from '../Common/Toast'

const clientId =
  '181971145518-5fvnai7toms6mjgvbrfkdh7hnhg41bpo.apps.googleusercontent.com'

const LogoutForm = () => {
  const [state, setState] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const handler = () => setState(true)
  //let history = useHistory();
  const { logoutHandler, profile } = useContext(authContext)

  const onLogoutSuccess = () => {
    logoutHandler()
    Toast('See you again soon!')
    setSubmitting(false)
  }

  const onFailure = res => {
    console.log(res.error)
  }

  const { signOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess,
    onFailure,
  })

  const closeHandler = event => {
    setState(false)
  }

  return (
    <>
      <Button type="error" onClick={handler} >
        Logout
      </Button>
      <Modal visible={state} onClose={closeHandler}>
        <Modal.Subtitle>Are you sure you wish to logout?</Modal.Subtitle>
        <Modal.Action passive onClick={() => setState(false)}>
          Cancel
        </Modal.Action>
        <Modal.Action type="error" loading={submitting ? true : false} onClick={profile?.googleId ? signOut : onLogoutSuccess}>Logout</Modal.Action>
      </Modal>
    </>
  )
}

export default LogoutForm
