import React from 'react'
import { Field } from 'formik'
import { Input, Text } from '@geist-ui/react'

function FormInput(props) {
  const { label, name, placeholder, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <div isInvalid={form.errors[name] && form.touched[name]}>
            <Input
              width="100%"
              placeholder={placeholder}
              id={name}
              {...field}>
              {label}
            </Input>
            <Text className="error">{form.errors[name]}</Text>
          </div>
        )
      }}
    </Field>
  )
}

export default FormInput
