import { POLLAXY_API_URL as API_URL } from "../Constants/actionTypes";
import { handleResponse } from "../Helpers";

export const configService = {
  getConfig
};

function getConfig() {
  const requestOptions = {
    method: "GET",
  };
  return fetch(`${API_URL}/config/`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}