import React, { createContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { userService, authService } from "../Services";
import Toast from "../Components/Common/Toast";

export const authContext = createContext({});

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ data: null });
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState(null);
  let history = useHistory();

  const setAuthData = (data) => {
    setAuth({ data: data });
  };

  async function getProfile() {
    userService
      .getProfile(auth.data?.token)
      .then((res) => {
        setProfile(res.user);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  async function logoutHandler() {
    setLoading(true);
    authService
      .logout()
      .then(() => {
        setAuth({ data: null });
        setProfile(null);
        history.push("/");
        setLoading(false);
      })
      .catch(() => {
        Toast("Something Went Wrong!", "Please try again later", "error");
        setLoading(false);
      });
  }

  useEffect(() => {
    authService.getCsrf();
  }, []);

  useEffect(() => {
    getProfile();
    setTimeout(function () {
      if (!auth.data) {
        setLoading(false);
      }
    }, 1500);
  }, [auth.data]);

  return (
    <authContext.Provider
      value={{
        auth,
        getProfile,
        setProfile,
        logoutHandler,
        loading,
        profile,
        setAuthData,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
