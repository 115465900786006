import React, { useContext } from 'react'
import Layout from './Layout/Layout'
import HelmetContainer from '../Components/Containers/HelmetContainer'
import { Text, Grid, Card } from '@geist-ui/react'
import { Link } from 'react-router-dom'
import { vatcmsContext } from '../Contexts/vatcmsProvider'
import ReactMarkdown from 'react-markdown'
import { useTheme } from '@geist-ui/react'

const About = () => {
  const { palette } = useTheme()
  const { infoLoading, pollpoliticInfo } = useContext(vatcmsContext)

  return (
    <Layout>
      <HelmetContainer title={'About'} />
      <>
        <Grid className="mb-2" align="center" md={0} sm={24} xs={24}>
          <Text className="text-center subHeader" h2>
            About <span className="blue">Poll</span>
            Politic
          </Text>
        </Grid>
        <Grid className="mb-2" align="center" md={24} sm={0} xs={0}>
          <Text className="text-center mt-4 subHeader" h2>
            .:: About <span className="blue">Poll</span>
            Politic ::.
          </Text>
        </Grid>
      </>
      <Card
        shadow
        className="mb-4"
        style={{ position: 'relative', zIndex: 2, background: palette.accents_1 }}
        align="left">
        <div className="offset-m3 col m6 offset-m3 s12">
          {!infoLoading ? (
            <ReactMarkdown>{pollpoliticInfo[0].overview}</ReactMarkdown>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="offset-m3 col m6 offset-m3 s12">
          <p>
            <Link to="/termsofservice">View our Terms of Service</Link>
          </p>
          <p>
            <Link to="/privacypolicy">View our Privacy Policy</Link>
          </p>
        </div>
        <div className="offset-m3 col m6 offset-m3 s12">
          {!infoLoading ? (
            <ReactMarkdown>{pollpoliticInfo[0].socialMedia}</ReactMarkdown>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="offset-m3 col m6 offset-m3 s12">
          {!infoLoading ? (
            <ReactMarkdown>{pollpoliticInfo[0].contact}</ReactMarkdown>
          ) : (
            <p>Loading...</p>
          )}
        </div>
        <div className="offset-m3 col m6 offset-m3 s12">
          {!infoLoading ? (
            <ReactMarkdown>{pollpoliticInfo[0].faqs}</ReactMarkdown>
          ) : (
            <p>Loading...</p>
          )}
          <div className="col s12">
            {!infoLoading ? (
              <ReactMarkdown>{pollpoliticInfo[0].rules}</ReactMarkdown>
            ) : (
              <p>Loading...</p>
            )}
          </div>
          {!infoLoading ? (
            <ReactMarkdown>{pollpoliticInfo[0].createPoll}</ReactMarkdown>
          ) : (
            <p>Loading...</p>
          )}
          <h6>Happy Polling!</h6>
        </div>
      </Card>
    </Layout>
  )
}

export default About
