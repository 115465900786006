import { POLLAXY_API_URL as API_URL } from "../Constants/actionTypes";
import { handleResponse, authHeader } from "../Helpers";

export const reportService = {
  getAllReports,
  getPollReports,
  getPostReports,
  getUserReports,
  addPollReport,
  addPostReport,
  addUserReport,
  resolveReport,
  flagPollReport,
  flagPostReport,
  deleteReport,
};

function getAllReports(page, q) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/reports?page=${page ? page : 1}${q ? `&q=${q}` : ""}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getPollReports(page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/reports/polls?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getPostReports(page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/reports/posts?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getUserReports(page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/reports/users?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addPollReport(payload) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/reports/polls`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addPostReport(payload) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/reports/posts`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function addUserReport(payload) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/reports/users`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function resolveReport(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/reports/${id}/resolve`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function flagPollReport(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/reports/${id}/flag/poll`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function flagPostReport(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/reports/${id}/flag/post`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deleteReport(id) {
  const requestOptions = {
    method: "DELETE",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/reports/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
