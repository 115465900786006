import { VATCMS_API_URL } from '../Constants/actionTypes'
import { handleResponse } from '../Helpers'

export const vatcmsService = {
  getInformation,
}

function getInformation() {
  const requestOptions = {
    method: 'GET',
  }
  return fetch(`${VATCMS_API_URL}/pollpolitics`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}
