import { Page } from '@geist-ui/react'
import Navbar from '../../Components/Navbar/Navbar'
import { useTheme } from '@geist-ui/react'

const Layout = props => {
  const theme = useTheme()
  return (
    <>
      <Navbar />
      <Page dotBackdrop={theme.type === "light" ? true : false} width="800px" padding={0}>
        {props.children}
      </Page>
      {/* {theme.type === 'light' ? ( */}
        <svg
          className="layoutSVG"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320">
          <path
            fill="#273036"
            fill-opacity="1"
            d="M0,192L0,96L110.8,96L110.8,192L221.5,192L221.5,256L332.3,256L332.3,192L443.1,192L443.1,224L553.8,224L553.8,96L664.6,96L664.6,64L775.4,64L775.4,224L886.2,224L886.2,192L996.9,192L996.9,256L1107.7,256L1107.7,160L1218.5,160L1218.5,224L1329.2,224L1329.2,128L1440,128L1440,320L1329.2,320L1329.2,320L1218.5,320L1218.5,320L1107.7,320L1107.7,320L996.9,320L996.9,320L886.2,320L886.2,320L775.4,320L775.4,320L664.6,320L664.6,320L553.8,320L553.8,320L443.1,320L443.1,320L332.3,320L332.3,320L221.5,320L221.5,320L110.8,320L110.8,320L0,320L0,320Z"></path>
        </svg>
    {/*    ) : (
         <svg
           xmlns="http://www.w3.org/2000/svg"
           className="layoutSVG"
           viewBox="0 0 1440 320">
           <path
             fill="#273036"
             fill-opacity="1"
             d="M0,192L0,96L110.8,96L110.8,192L221.5,192L221.5,256L332.3,256L332.3,192L443.1,192L443.1,224L553.8,224L553.8,96L664.6,96L664.6,64L775.4,64L775.4,224L886.2,224L886.2,192L996.9,192L996.9,256L1107.7,256L1107.7,160L1218.5,160L1218.5,224L1329.2,224L1329.2,128L1440,128L1440,0L1329.2,0L1329.2,0L1218.5,0L1218.5,0L1107.7,0L1107.7,0L996.9,0L996.9,0L886.2,0L886.2,0L775.4,0L775.4,0L664.6,0L664.6,0L553.8,0L553.8,0L443.1,0L443.1,0L332.3,0L332.3,0L221.5,0L221.5,0L110.8,0L110.8,0L0,0L0,0Z"></path>
         </svg>
         )} */} 
    </>
  )
}

export default Layout
