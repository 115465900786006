import React, { useContext } from "react";
import { Field } from "formik";
import { Text } from "@geist-ui/react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { formContext } from "../../Contexts/FormProvider";

registerPlugin(FilePondPluginImagePreview);

function FormFile(props) {
  const { label, name, value, ...rest } = props;
  const { setImage } = useContext(formContext);
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <div isInvalid={form.errors[name] && form.touched[name]}>
            <label htmlFor={name}>{label}</label>
            <FilePond
              variant="unstyled"
              onupdatefiles={(fileItems) => {
                setImage(fileItems);
                if (fileItems.length) {
                  form.setFieldValue(name, fileItems);
                } else {
                  form.setFieldValue(name, "");
                }
              }}
              allowMultiple={false}
              name="image"
              id={name}
              {...rest}
              {...field}
            />
            <Text className="error">{form.errors[name]}</Text>
          </div>
        );
      }}
    </Field>
  );
}

export default FormFile;
