import React, { useState, useContext, useEffect } from 'react'
import { Button, Grid, Text } from '@geist-ui/react'
import { FormikControl } from '../../Reducers'
import { Formik } from 'formik'
import Toast from '../Common/Toast'
import * as Yup from 'yup'
import { communityPollService, uploadService } from '../../Services'
import { CATEGORY_TYPES } from '../../Constants'
import { formContext } from '../../Contexts/FormProvider'
import { useHistory } from 'react-router-dom'
import HelmetContainer from '../Containers/HelmetContainer'

const PollForm = () => {
  const { references, clearReferences, choices, image, tags } = useContext(formContext)
  const [submitting, setSubmitting] = useState(false)
  const history = useHistory()
  const initialValues = {
    category: '',
    name: '',
    image: image,
    choices: choices,
    tags: '',
    references: references,
  }

  const validationSchema = Yup.object({
    category: Yup.string().required('Required'),
    image: Yup.mixed().required('Required'),
    references: Yup.array().min(1).required('Required'),
    choices: Yup.array().min(2).required('Required'),
    name: Yup.string()
      .min(10, 'Must be atleast 10 characters')
      .max(100, 'Must be at the most 100 characters')
      .required('Required'),
  })

  const onSubmit = (values, { resetForm }) => {
    setSubmitting(true)
    values.references = references
    values.tags = tags
    values.choices = choices
    uploadService
      .addImage(image)
      .then(res => {
        values.image = res
        completePollSubmission(values, resetForm)
      })
      .catch(res => {
        setSubmitting(false)
        Toast(res)
      })
  }

  function completePollSubmission(values, resetForm) {
    communityPollService
      .addCommunityPoll(values)
      .then(() => {
        setSubmitting(false)
        resetForm({})
        Toast('Poll Submitted and Waiting for Approval!')
        history.push('/communitypolls')
      })
      .catch(res => {
        setSubmitting(false)
        Toast(res)
      })
  }
  useEffect(() => {
    return () => {
      clearReferences()
    }
  }, [])
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <div className="mb-4 px-4">
            <HelmetContainer title="Create Community Poll" />

            <Grid align="center" md={0} sm={24} xs={24}>
              <Text className="text-center mb-4 subHeader">Create Community Poll</Text>
            </Grid>
            <Grid align="center" md={24} sm={0} xs={0}>
              <Text className="text-center mb-4 subHeader">
                .:: Create Community Poll ::.
              </Text>
            </Grid>
            <FormikControl
              control="radio"
              type="radio"
              label="Category"
              name="category"
              options={CATEGORY_TYPES}
            />
            <FormikControl
              control="input"
              name="name"
              label="Name"
              placeholder="Name of your poll"
            />
            <FormikControl control="image" name="image" label="Image" />
            <FormikControl control="tags" name="tags" label="Tags" />
            <Grid.Container>
              <Grid xs={12}>
                <FormikControl control="choices" name="choices" label="Choices" />
              </Grid>
              <Grid xs={12}>
                <FormikControl
                  control="references"
                  name="references"
                  label="References"
                />
              </Grid>
            </Grid.Container>
            <Button
              onClick={() => formik.submitForm()}
              type="secondary"
              shadow
              loading={submitting ? true : false}>
              Add Your Poll
            </Button>
          </div>
        )
      }}
    </Formik>
  )
}
export default PollForm
