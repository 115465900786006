import React, { useContext, useState } from 'react'
import { userService } from '../../Services'
import { authContext } from '../../Contexts/authProvider'
import Toast from '../Common/Toast'
import { Button } from '@geist-ui/react'

const Follow = ({ data }) => {
  const { profile } = useContext(authContext)
  const [loading, setLoading] = useState(false)
  const [currentFollowing, setCurrentFollowing] = useState(profile.following)

  async function follow() {
    setLoading(true)
    await userService
      .followUser(data._id)
      .then(() => {
        setCurrentFollowing([data._id, ...currentFollowing])
        setLoading(false)
      })
      .catch(res => {
        setLoading(false)
        Toast(res)
      })
  }
  async function unfollow() {
    setLoading(true)
    await userService
      .unfollowUser(data._id)
      .then(() => {
        setCurrentFollowing(currentFollowing.filter(item => item !== data._id))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        Toast('Unable to follow this user')
      })
  }
  return (
    <>
      {currentFollowing.find(fol => fol.toString() === data._id.toString()) ? (
        <Button loading={loading} onClick={() => unfollow()} auto>
          Unfollow
        </Button>
      ) : (
        <Button loading={loading} onClick={() => follow()} auto>
          Follow
        </Button>
      )}
    </>
  )
}

export default Follow
