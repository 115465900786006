import React, { useState } from 'react'
import { Button, Modal, useTheme } from '@geist-ui/react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../Reducers'

const EditPostForm = ({ postId, pollId, toEdit, prevValue, setModalState }) => {
  const [state, setState] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const handler = () => setState(true)
  const { palette } = useTheme()

  const initialValues = {
    body: prevValue,
  }

  const validationSchema = Yup.object({
    body: Yup.string().required('Required'),
  })

  const onSubmit = values => {
    setSubmitting(true)
    toEdit(pollId, postId, values)
    setSubmitting(false)
    setState(false)
    setModalState(false)
  }

  const closeHandler = event => {
    setState(false)
  }
  return (
    <>
      <Button className="mb-2" onClick={handler}>
        Edit Post
      </Button>{' '}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {formik => {
          return (
            <Modal
              visible={state}
              onClose={closeHandler}>
              <Modal.Title>Edit Post</Modal.Title>
              <Modal.Content>
                <FormikControl
                  control="textarea"
                  name="body"
                  placeholder="Share your Thoughts!"
                />
              </Modal.Content>
              <Modal.Action passive onClick={() => setState(false)}>
                Cancel
              </Modal.Action>
              <Modal.Action
                loading={submitting ? true : false}
                onClick={() => formik.submitForm()}>
                Save
              </Modal.Action>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}
export default EditPostForm
