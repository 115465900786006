import React, { useState } from 'react'
import { Button, Modal } from '@geist-ui/react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../Reducers'
import { ISSUE_TYPES } from '../../Constants'
import { reportService } from '../../Services'
import Toast from '../Common/Toast'

const ReportForm = ({ postId, pollId, type, setModalState }) => {
  const [state, setState] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const handler = () => setState(true)

  const initialValues = {
    id: type === 'poll' ? pollId : postId,
    reason: '',
    issue: '',
  }

  const validationSchema = Yup.object({
    reason: Yup.string()
      .min(4, 'Must be atleast 4 characters')
      .max(255, 'Must be at the most 255 characters')
      .required('Required'),
    issue: Yup.string().required('Required'),
  })

  const onSubmit = values => {
    setSubmitting(true)
    if (type === 'poll') {
      reportService
        .addPollReport(values)
        .then(() => {
          Toast('Poll was Reported')
          setSubmitting(false)
          onClose()
        })
        .catch(res => {
          setSubmitting(false)
          Toast(res)
        })
    } else if (type === 'post') {
      reportService
        .addPostReport(values)
        .then(() => {
          Toast('Post was Reported')
          setSubmitting(false)
          onClose()
        })
        .catch(res => {
          setSubmitting(false)
          Toast(res)
        })
    }
    setState(false)
    setModalState(false)
  }

  const closeHandler = event => {
    setState(false)
  }
  return (
    <>
      <Button type="warning" className="mb-2" onClick={handler}>
        {type === 'poll' ? 'Report Poll' : 'Report Post'}
      </Button>{' '}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {formik => {
          return (
            <Modal visible={state} onClose={closeHandler}>
              <Modal.Title>{type === 'poll' ? 'Report Poll' : 'Report Post'}</Modal.Title>
              <Modal.Content>
                <FormikControl
                  control="radio"
                  type="radio"
                  label="Reportable Issues"
                  name="issue"
                  options={ISSUE_TYPES}
                />
                <FormikControl
                  control="textarea"
                  name="reason"
                  placeholder="Reason for your report"
                />
              </Modal.Content>
              <Modal.Action passive onClick={() => setState(false)}>
                Cancel
              </Modal.Action>
              <Modal.Action
                loading={submitting ? true : false}
                onClick={() => formik.submitForm()}>
                Save
              </Modal.Action>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}
export default ReportForm
