import React, { createContext, useState, useEffect } from "react";
import { vatcmsService } from "../Services";
import Toast from "../Components/Common/Toast";

export const vatcmsContext = createContext({});

const VatCMSProvider = ({ children }) => {
  const [infoLoading, setInfoLoading] = useState(true);
  const [pollpoliticInfo, setPollpoliticInfo] = useState(null);

  async function retrieveInfo() {
    vatcmsService
      .getInformation()
      .then((res) => {    
        setPollpoliticInfo(res);
        setInfoLoading(false);
      })
      .catch(() => {
        setInfoLoading(false);
        Toast("Something Went Wrong", "Could not retrieve PollPolitic information", "error")
      });
  }

  useEffect(() => {
    retrieveInfo();
  }, []);

  return (
    <vatcmsContext.Provider
      value={{
        infoLoading,
        pollpoliticInfo,
      }}
    >
      {children}
    </vatcmsContext.Provider>
  );
};

export default VatCMSProvider;
