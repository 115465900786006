import React from 'react'
import {
  Grid,
  Button,
  Text,

} from '@geist-ui/react'

const ErrorState = ({ error, retry }) => {
  return (
    <Grid className="mt-4 text-center">
        <Text type="error" h4 className="mt-4 mb-1">
          Something Went Wrong!
        </Text>
        <Text>
          {retry ? <Button type="error">Retry</Button> : null}
        </Text>
    </Grid>
  )
}

export { ErrorState }
