import React, { useState, useContext } from 'react'
import { authContext } from '../../Contexts/authProvider'
import { Button, Drawer, Grid } from '@geist-ui/react'
import { MoreVertical } from '@geist-ui/react-icons'
import Delete from '../Common/Delete'
import { FormControl } from '../../Reducers'
import { useTheme } from '@geist-ui/react'

const OptionContainer = ({
  user,
  postId,
  pollId,
  toDelete,
  type,
  toEdit,
  prevValue,
  noOp,
}) => {
  const [state, setState] = useState(false)
  const { profile } = useContext(authContext)
  const { palette } = useTheme()

  return (
    <>
      {!noOp && (
        <>
          {user && profile ? (
            <Button
              pr={0.3}
              onClick={() => setState(true)}
              className="mr-0"
              iconRight={<MoreVertical />}
              auto
            />
          ) : null}
          <Drawer
            style={{ background: palette.accents_1 }}
            visible={state}
            onClose={() => setState(false)}
            placement="right">
            <Drawer.Title>Options</Drawer.Title>
            <Drawer.Content>
              <Grid.Container style={{ width: '200px' }} alignContent="column">
                <Grid xs={24}>
                  <FormControl
                    setModalState={setState}
                    postId={postId}
                    pollId={pollId}
                    type={type}
                    control="report"
                  />
                </Grid>
                {profile
                  ? !!profile.posts.find(post => post === postId) && (
                      <Grid xs={24}>
                        <FormControl
                          setModalState={setState}
                          postId={postId}
                          pollId={pollId}
                          toEdit={toEdit}
                          prevValue={prevValue}
                          control="editpost"
                        />
                      </Grid>
                    )
                  : null}
                {profile
                  ? !!profile.posts.find(post => post === postId) && (
                      <Grid xs={24}>
                        <Delete
                          setModalState={setState}
                          toDelete={toDelete}
                          type={type}
                          postId={postId}
                          pollId={pollId}
                        />
                      </Grid>
                    )
                  : null}
              </Grid.Container>
            </Drawer.Content>
          </Drawer>
        </>
      )}
    </>
  )
}

export default OptionContainer
