import React from "react";
import { Field } from "formik";
import {
  Textarea,
  Text,
} from "@geist-ui/react";

function FormTextarea(props) {
  const { label, name, placeholder, ...rest } = props;
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <div isInvalid={form.errors[name] && form.touched[name]}>
            <Textarea
              id={name}
              {...rest}
              {...field}
              width="100%"
              height="100px"
              placeholder={placeholder}
            />
            <Text className="error">{form.errors[name]}</Text>
          </div>
        );
      }}
    </Field>
  );
}

export default FormTextarea;
