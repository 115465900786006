import React from 'react'
import { Text, Card, Grid } from '@geist-ui/react'
import { Link } from 'react-router-dom'
import LikeDislike from '../LikeDislike/LikeDislike'
import CopyLink from '../CopyLink/CopyLink'
import UserContainer from './UserContainer'
import OptionContainer from './OptionContainer'
import { ArrowLeft } from '@geist-ui/react-icons'
import Moment from 'react-moment'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@geist-ui/react'

const PostContainer = props => {
  let history = useHistory()
  const { palette } = useTheme()
  const historyHandler = () => {
    history.push(`/posts/${data._id}`)
  }
  const { data, profile, single, toDelete, toEdit, prevValue } = props
  return (
    <Card
      className="mb-2"
      shadow
      style={{ background: palette.accents_1, position: 'relative', zIndex: 2 }}
      align="left">
      {!single && (
        <Text className="mb-4" h6>
          <Link to={`/polls/${data.poll}`}>
            <ArrowLeft size="12" /> Return to Poll
          </Link>
        </Text>
      )}
      <Grid.Container>
        {data.user ? <UserContainer user={data.user} /> : <UserContainer user={profile} />}
        <Grid align="right" mt={0.5} xs={4}>
          <Text small em>
            <Moment fromNow>{data.createdAt}</Moment>
          </Text>
        </Grid>
      </Grid.Container>{' '}
        <Text className="pointer" onClick={() => historyHandler()}>{data.body}</Text>
      <Card.Footer>
        <Grid.Container>
          <Grid align="left" xs={17}>
            {/* <Dot style={{ marginRight: '10px', fontSize: '12px' }} type="warning">
              {postCount} Posts
            </Dot> */}
            <LikeDislike data={data} />
          </Grid>
          <Grid justify="flex-end" xs={6}>
            <CopyLink id={data._id} pollOrPost="posts" />
            <OptionContainer
              type="post"
              profile={profile}
              user={data.user}
              postId={data._id}
              pollId={data.poll}
              toDelete={toDelete}
              toEdit={toEdit}
              prevValue={prevValue}
            />
          </Grid>
        </Grid.Container>
      </Card.Footer>
    </Card>
  )
}

export default PostContainer
