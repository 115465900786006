import React, { useState } from 'react'
import { Button, Card, useTheme } from '@geist-ui/react'
import { FormikControl } from '../../Reducers'
import { Formik } from 'formik'
import Toast from '../Common/Toast'
import * as Yup from 'yup'
import { postService } from '../../Services'

const PostForm = ({ id, postAdded }) => {
  const [submitting, setSubmitting] = useState(false)
  const { palette } = useTheme()
  const initialValues = {
    post: '',
  }

  const validationSchema = Yup.object({
    post: Yup.string().required('Required'),
  })

  const onSubmit = (values, { resetForm }) => {
    setSubmitting(true)
    postService
      .addPost(id, values)
      .then((res) => {
        setSubmitting(false)
        Toast('Post Added!')
        postAdded(res)
        resetForm({})
      })
      .catch(res => {
        setSubmitting(false)
        Toast(res, 'error-toast')
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <Card
            shadow
            className="mb-2"
            style={{
              position: 'relative',
              zIndex: 2,
              color: 'white',
              background: palette.accents_1 
            }}
            align="left">
            <FormikControl
              control="textarea"
              name="post"
              placeholder="Share your Thoughts!"
            />
            <Button
              onClick={() => formik.submitForm()}
              loading={submitting ? true : false}
              type="secondary"
              shadow>
              Add Post
            </Button>
          </Card>
        )
      }}
    </Formik>
  )
}
export default PostForm
