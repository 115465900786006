import React, { useState, useEffect } from 'react'
import Layout from './Layout/Layout'
import { FormControl } from '../Reducers'
import FormProvider from '../Contexts/FormProvider'
import { Card } from '@geist-ui/react'
import { useTheme } from '@geist-ui/react'

const Form = props => {
  const { route } = props
  const { palette } = useTheme()
  const [value, setValue] = useState(route)
  const handleRP = val => {
    setValue(val)
  }

  useEffect(() => {
    setValue(route)
  }, [route])

  return (
    <Layout>
      <FormProvider>
        <Card style={{ background: palette.accents_1 }} className="cardCSS mt-4" shadow>
          <FormControl handleRP={handleRP} {...props} control={value} />
        </Card>
      </FormProvider>
    </Layout>
  )
}

export default Form
