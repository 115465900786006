import React from 'react'
import { Text, Grid, Card } from '@geist-ui/react'
import Layout from './Layout/Layout'
import HelmetContainer from '../Components/Containers/HelmetContainer'

const PrivacyPolicy = () => {
  return (
    <Layout>
      <HelmetContainer title="Privacy Policy" />
      <Card
        shadow
        className="my-4"
        style={{ position: 'relative', zIndex: 2 }}
        align="left">
        <Grid flexWrap="wrap-reverse" className="mt-1" alignItems="center">
          <Text className="mb-1" h3>
            PollPolitic
          </Text>
          <Text className="mb-4 mt-1" h2>
            Privacy Policy
          </Text>
          <Text className="my-4" h5>
            Your privacy is critically important to us.
          </Text>
          <Text>
            It is PollPolitic's policy to respect your privacy regarding any information
            we may collect while operating our website. This Privacy Policy applies to{' '}
            <a href="https://www.PollPolitic.com">https://www.PollPolitic.com</a>{' '}
            (hereinafter, "us", "we", or "https://www.PollPolitic.com"). We respect your
            privacy and are committed to protecting personally identifiable information
            you may provide us through the Website. We have adopted this privacy policy
            ("Privacy Policy") to explain what information may be collected on our
            Website, how we use this information, and under what circumstances we may
            disclose the information to third parties. This Privacy Policy applies only to
            information we collect through the Website and does not apply to our
            collection of information from other sources.
          </Text>
          <Text>
            This Privacy Policy, together with the Terms and conditions posted on our
            Website, set forth the general rules and policies governing your use of our
            Website. Depending on your activities when visiting our Website, you may be
            required to agree to additional terms and conditions.
          </Text>
          <Text className="my-4" h5>
            Website Visitors
          </Text>
          <Text>
            Like most website operators, PollPolitic collects non-personally-identifying
            information of the sort that web browsers and servers typically make
            available, such as the browser type, language preference, referring site, and
            the date and time of each visitor request. PollPolitic's purpose in collecting
            non-personally identifying information is to better understand how
            PollPolitic's visitors use its website. From time to time, PollPolitic may
            release non-personally-identifying information in the aggregate, e.g., by
            publishing a report on trends in the usage of its website.
          </Text>
          <Text>
            PollPolitic also collects potentially personally-identifying information like
            Internet Protocol (IP) addresses for logged in users and for users leaving
            comments on https://www.PollPolitic.com blog posts. PollPolitic only discloses
            logged in user and commenter IP addresses under the same circumstances that it
            uses and discloses personally-identifying information as described below.
          </Text>
          <Text className="my-4" h5>
            Gathering of Personally-Identifying Information
          </Text>
          <Text>
            Certain visitors to PollPolitic's websites choose to interact with PollPolitic
            in ways that require PollPolitic to gather personally-identifying information.
            The amount and type of information that PollPolitic gathers depends on the
            nature of the interaction. For example, we ask visitors who sign up for a blog
            at https://www.PollPolitic.com to provide a username and email address.
          </Text>
          <Text className="my-4" h5>
            Security
          </Text>
          <Text>
            The security of your Personal Information is important to us, but remember
            that no method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially acceptable means
            to protect your Personal Information, we cannot guarantee its absolute
            security.
          </Text>
          <Text className="my-4" h5>
            Advertisements
          </Text>
          <Text>
            Ads appearing on our website may be delivered to users by advertising
            partners, who may set cookies. These cookies allow the ad server to recognize
            your computer each time they send you an online advertisement to compile
            information about you or others who use your computer. This information allows
            ad networks to, among other things, deliver targeted advertisements that they
            believe will be of most interest to you. This Privacy Policy covers the use of
            cookies by PollPolitic and does not cover the use of cookies by any
            advertisers.
          </Text>
          <Text className="my-4" h5>
            Links To External Sites
          </Text>
          <Text>
            Our Service may contain links to external sites that are not operated by us.
            If you click on a third party link, you will be directed to that third party's
            site. We strongly advise you to review the Privacy Policy and terms and
            conditions of every site you visit.
          </Text>
          <Text>
            We have no control over, and assume no responsibility for the content, privacy
            policies or practices of any third party sites, products or services.
          </Text>
          <Text className="my-4" h5>
            Protection of Certain Personally-Identifying Information
          </Text>
          <Text>
            PollPolitic discloses potentially personally-identifying and
            personally-identifying information only to those of its employees, contractors
            and affiliated organizations that (i) need to know that information in order
            to process it on PollPolitic's behalf or to provide services available at
            PollPolitic's website, and (ii) that have agreed not to disclose it to others.
            Some of those employees, contractors and affiliated organizations may be
            located outside of your home country; by using PollPolitic's website, you
            consent to the transfer of such information to them. PollPolitic will not rent
            or sell potentially personally-identifying and personally-identifying
            information to anyone. Other than to its employees, contractors and affiliated
            organizations, as described above, PollPolitic discloses potentially
            personally-identifying and personally-identifying information only in response
            to a subpoena, court order or other governmental request, or when PollPolitic
            believes in good faith that disclosure is reasonably necessary to protect the
            property or rights of PollPolitic, third parties or the public at large.
          </Text>
          <Text>
            If you are a registered user of https://www.PollPolitic.com and have supplied
            your email address, PollPolitic may occasionally send you an email to tell you
            about new features, solicit your feedback, or just keep you up to date with
            what's going on with PollPolitic and our products. We primarily use our blog
            to communicate this type of information, so we expect to keep this type of
            email to a minimum. If you send us a request (for example via a support email
            or via one of our feedback mechanisms), we reserve the right to publish it in
            order to help us clarify or respond to your request or to help us support
            other users. PollPolitic takes all measures reasonably necessary to protect
            against the unauthorized access, use, alteration or destruction of potentially
            personally-identifying and personally-identifying information.
          </Text>
          <Text className="my-4" h5>
            Aggregated Statistics
          </Text>
          <Text>
            PollPolitic may collect statistics about the behavior of visitors to its
            website. PollPolitic may display this information publicly or provide it to
            others. However, PollPolitic does not disclose your personally-identifying
            information.
          </Text>
          <Text className="my-4" h5>
            Cookies
          </Text>
          <Text>
            To enrich and perfect your online experience, PollPolitic uses "Cookies",
            similar technologies and services provided by others to display personalized
            content, appropriate advertising and store your preferences on your computer.
          </Text>
          <Text>
            A cookie is a string of information that a website stores on a visitor's
            computer, and that the visitor's browser provides to the website each time the
            visitor returns. PollPolitic uses cookies to help PollPolitic identify and
            track visitors, their usage of https://www.PollPolitic.com, and their website
            access preferences. PollPolitic visitors who do not wish to have cookies
            placed on their computers should set their browsers to refuse cookies before
            using PollPolitic's websites, with the drawback that certain features of
            PollPolitic's websites may not function properly without the aid of cookies.
          </Text>
          <Text>
            By continuing to navigate our website without changing your cookie settings,
            you hereby acknowledge and agree to PollPolitic's use of cookies.
          </Text>
          <Text className="my-4" h5>
            Privacy Policy Changes
          </Text>
          <Text>
            Although most changes are likely to be minor, PollPolitic may change its
            Privacy Policy from time to time, and in PollPolitic's sole discretion.
            PollPolitic encourages visitors to frequently check this page for any changes
            to its Privacy Policy. Your continued use of this site after any change in
            this Privacy Policy will constitute your acceptance of such change.
          </Text>
          <Text className="my-4" h5>
            Credit and Contact Information
          </Text>
          <Text>
            This privacy policy was created at{' '}
            <a
              href="https://termsandconditionstemplate.com/privacy-policy-generator/"
              title="Privacy policy template generator">
              termsandconditionstemplate.com
            </a>
            . If you have any questions about this Privacy Policy, please contact us via{' '}
            <a href="mailto:info@pollpolitic.com">email</a>
          </Text>
        </Grid>
      </Card>
    </Layout>
  )
}

export default PrivacyPolicy
