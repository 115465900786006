import React, { useState, useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../Reducers'
import { authService } from '../../Services'
import { authContext } from '../../Contexts/authProvider'
import { Button, Grid, Text } from '@geist-ui/react'
import { useHistory } from 'react-router-dom'
import HelmetContainer from '../Containers/HelmetContainer'
import Toast from '../Common/Toast'

function ForgotPasswordForm() {
  const [submitting, setSubmitting] = useState(false)
  const { profile } = useContext(authContext)
  let history = useHistory()

  if (profile) {
    history.push('/')
  }

  const initialValues = {
    email: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
  })

  const onSubmit = values => {
    setSubmitting(true)
    authService
      .recover(values)
      .then(() => {
        setSubmitting(false)
        Toast('If an account exists, an email was sent to that address')
        history.push('/signin')
      })
      .catch(res => {
        setSubmitting(false)
        Toast('Something Went Wrong! Please try again later')
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <Grid.Container>
            <HelmetContainer title="Forgot Password" />
            <Grid direction="column" className="px-4 pb-4" md={12} xs={24}>
              <Text h2 className="mt-4 mb-4">
                Reset Password
              </Text>
              <Form>
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Email"
                />
                <Button
                  w="100%"
                  onClick={() => formik.submitForm()}
                  className="mt-2"
                  type="submit"
                  isLoading={submitting ? true : false}>
                  Submit
                </Button>
              </Form>
            </Grid>
            <Grid className="signInBg" direction="column" md={12} xs={24}></Grid>
          </Grid.Container>
        )
      }}
    </Formik>
  )
}

export default ForgotPasswordForm
