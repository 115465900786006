import React, { useContext, useState } from 'react'
import { Text, Button, Grid } from '@geist-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { FormikControl } from '../../Reducers'
import GoogleForm from './GoogleForm'
import FacebookForm from './FacebookForm'
import Toast from '../Common/Toast'
import { authContext } from '../../Contexts/authProvider'
import { authService } from '../../Services'
import HelmetContainer from '../Containers/HelmetContainer'

const LoginForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [resendLink, setResendLink] = useState(false)
  let history = useHistory()
  const { setAuthData, profile } = useContext(authContext)

  if (profile) {
    history.push('/')
  }

  const initialValues = {
    email: '',
    password: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Required'),
    password: Yup.string().required('Required'),
  })

  const onSubmit = values => {
    setSubmitting(true)
    authService
      .login(values)
      .then(res => {
        setAuthData(res)
        setSubmitting(false)
        Toast('Welcome back to PollPolitic!')
        history.push('/')
      })
      .catch(res => {
        setSubmitting(false)
        if (res === 'Your account has not been verified.') {
          setResendLink(true)
        }
        Toast(res, 'error-toast')
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <Grid.Container>
            <HelmetContainer title="Sign In" />

            <Grid direction="column" className="px-4" md={12} xs={24}>
              <Text className="bold mt-4" h2>
                Welcome Back to <span className="blue">Poll</span>Politic!
              </Text>
              {/* <Grid direction="row" xs={24}> */}
                <GoogleForm />
                {/* <FacebookForm /> */}
                {/* <TwitterForm /> */}
              {/* </Grid> */}
              <Form>
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Email"
                />
                <FormikControl
                  control="password"
                  label="Password"
                  name="password"
                  placeholder="Password"
                />
                <Button
                  onClick={() => formik.submitForm()}
                  loading={submitting ? true : false}
                  type="secondary"
                  className="mt-2"
                  shadow>
                  Submit
                </Button>
              </Form>
              <Text className="pt-3" h6>
                <Link className="link" to="/resetpassword">
                  Reset Password?
                </Link>
              </Text>
              {resendLink ? (
                <Text h6 className="link" onClick={() => resendToken(formik)}>
                  Resend Verification Email?
                </Text>
              ) : null}
              <Text className="pb-4" h6>
                Don't Have An Account?{' '}
                <Link className="link" to="/signup">
                  Sign Up
                </Link>
              </Text>
            </Grid>
            <Grid className="signInBg" direction="column" md={12} xs={0}></Grid>
          </Grid.Container>
        )
      }}
    </Formik>
  )
}
export default LoginForm
