import React, { useState, useEffect } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'
import { Spacer } from '@geist-ui/react'

const AlignmentResults = ({ data }) => {
  const [chartData, setChartData] = useState([])
  useEffect(() => {
    setChartData([])
    data.forEach(item => {
      let tempData = new Object()
      if (item.alignmentResults.length) {
        item.alignmentResults.map(a => {
          return (tempData[a.name] = a.votes)
        })
        tempData.name = item.value
        setChartData(chartData => [...chartData, tempData])
      } else {
        return null
      }
    })
  }, [data])
  return (
    <>
      <ResponsiveContainer width="99%" aspect={window.matchMedia("(min-width: 768px)").matches ? 3 : 1}>
        <BarChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend />
          <Bar dataKey="Left" fill="#42A5F5" />
          <Bar dataKey="Center" fill="#AB47BC" />
          <Bar dataKey="Right" fill="#EF5350" />
        </BarChart>
      </ResponsiveContainer>
      <Spacer />
    </>
  )
}

export default AlignmentResults
