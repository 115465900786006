import { Themes } from '@geist-ui/react'

export const PollPoliticDark = Themes.createFromDark({
    type: 'pollPoliticDark',
    palette: {
        accents_1: '#212121',
        background: '#323232',
        foreground: '#fff',
        selection: "#212121"
    },
  })