import React, { useContext, useState } from 'react'
import { FormikControl } from '../../Reducers'
import { Formik } from 'formik'
import Toast from '../Common/Toast'
import * as Yup from 'yup'
import { userService, uploadService } from '../../Services'
import { Button, Grid } from '@geist-ui/react'
import { STATE_TYPES, ALIGNMENT_TYPES } from '../../Constants'
import { formContext } from "../../Contexts/FormProvider";
import { authContext } from "../../Contexts/authProvider";
import LogoutForm from './LogoutForm'

const EditUserForm = ({ user, updateUser }) => {
  const { image } = useContext(formContext)
  const { setProfile } = useContext(authContext)
  const [submitting, setSubmitting] = useState(false)
  const initialValues = {
    displayName: user.displayName,
    bio: user.bio,
    politicalAlignment: user.politicalAlignment,
    state: user.state,
    country: user.country,
    darkMode: user.darkMode,
  }

  const validationSchema = Yup.object({
    //   post: Yup.string().required('Required'),
  })

  const onSubmit = (values, { resetForm }) => {
    setSubmitting(true)
    if (image) {
      uploadService
        .addImage(image)
        .then(res => {
          values.profileImage = res;
          values.image = null;
          completeEditUser(values, resetForm)
        })
        .catch(res => {
          setSubmitting(false)
          Toast(res)
        })
    } else {
      completeEditUser(values, resetForm)
    }
  }

  function completeEditUser(values, resetForm) {
    userService
      .updateUser(user._id, values)
      .then(res => {
        setSubmitting(false)
        updateUser(res)
        setProfile(res)
        Toast('User Updated!')
      })
      .catch(res => {
        setSubmitting(false)
        Toast(res)
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <>
            <Grid.Container>
              <Grid direction="column" xs="12">
                <FormikControl
                  label="Display Name"
                  control="input"
                  name="displayName"
                  placeholder="Add a display name"
                />
              </Grid>
              <Grid className="pl-4" direction="column" xs="12">
                <label>Toggle Dark Mode</label>
                <FormikControl
                  label="Dark Mode"
                  control="check"
                  name="darkMode"
                  placeholder="Dark Mode"
                />
              </Grid>
            </Grid.Container>
            <div className="mb-1">
              <label>Biography</label>
            </div>
            <FormikControl
              label="Biography"
              control="textarea"
              name="bio"
              placeholder="Add a biography"
            />
            <FormikControl control="image" name="profileImage" label="Profile Image" />
            <Grid.Container>
              <Grid direction="column" xs="8">
                <FormikControl
                  options={ALIGNMENT_TYPES}
                  control="select"
                  label="Political Alignment"
                  name="politicalAlignment"
                />
              </Grid>
              <Grid direction="column" xs="8">
                <FormikControl
                  options={STATE_TYPES}
                  control="select"
                  label="State"
                  name="state"
                />
              </Grid>
              <Grid direction="column" xs="8">
                <FormikControl
                  control="input"
                  label="Country"
                  name="country"
                  placeholder="Add your Country"
                />
              </Grid>
            </Grid.Container>
            <Button
              onClick={() => formik.submitForm()}
              loading={submitting ? true : false}
              type="secondary"
              className="mr-2 mb-1"
              shadow>
              Update Profile
            </Button>
            <LogoutForm/>
          </>
        )
      }}
    </Formik>
  )
}

export default EditUserForm