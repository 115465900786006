import React from 'react'
import { Field } from 'formik'
import { Text, Input } from '@geist-ui/react'

function FormPassword(props) {
  const { label, name, placeholder, ...rest } = props

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <div isInvalid={form.errors[name] && form.touched[name]}>
            <Input.Password
              width="100%"
              placeholder={placeholder}
              id={name}
              {...rest}
              {...field}>
              {label}
            </Input.Password>
            <Text className="error">{form.errors[name]}</Text>
          </div>
        )
      }}
    </Field>
  )
}

export default FormPassword
