import React from 'react'
import { hydrate, render } from "react-dom";
import App from './app'
import { BrowserRouter as Router } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import AuthProvider from './Contexts/authProvider'
import VatCMSProvider from './Contexts/vatcmsProvider'
import ConfigProvider from './Contexts/configProvider'
import { HelmetProvider } from 'react-helmet-async'

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(  <React.StrictMode>
    <Router>
      <HelmetProvider>
        <ConfigProvider>
          <VatCMSProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </VatCMSProvider>
        </ConfigProvider>
      </HelmetProvider>
    </Router>
  </React.StrictMode>, rootElement);
} else {
  render(  <React.StrictMode>
    <Router>
      <HelmetProvider>
        <ConfigProvider>
          <VatCMSProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </VatCMSProvider>
        </ConfigProvider>
      </HelmetProvider>
    </Router>
  </React.StrictMode>, rootElement);
}
