import React, { useState, useContext } from 'react'
import { authContext } from '../../Contexts/authProvider'
import { useTheme } from '@geist-ui/react'
import {
  Text,
  Card,
  Avatar,
  Badge,
  Dot,
  Grid,
  Spacer,
  Button,
  Description,
} from '@geist-ui/react'
import { XOctagon } from '@geist-ui/react-icons'
import CopyLink from '../CopyLink/CopyLink'
import UserContainer from './UserContainer'
import OptionContainer from './OptionContainer'
import TagContainer from './TagContainer'
import ReferenceContainer from './ReferenceContainer'
import { uppercase } from '../../Helpers'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'
import Favorite from '../Favorite/Favorite'
import VoteForm from '../Forms/VoteForm'
import GeneralResults from '../Charts/GeneralResults'
import AlignmentResults from '../Charts/AlignmentResults'
import { countVotes } from '../../Helpers'
import Posts from '../../Pages/Posts'
import { useHistory } from 'react-router-dom'
import Fade from 'react-reveal/Fade'

const PollContainer = ({ data, single, type, fetchPoll }) => {
  const { profile } = useContext(authContext)
  let history = useHistory()
  const [voted, setVoted] = useState(profile ? pollType() : false)
  const theme = useTheme()
  const historyHandler = () => {
    history.push(`/polls/${data._id}`)
  }

  function pollType() {
    var consistentType = uppercase(type)
    if (consistentType === 'Official') {
      return !!profile.officalVotes.find(
        poll => poll.poll.toString() === data._id.toString(),
      )
        ? true
        : false
    } else if (consistentType === 'Community') {
      return !!profile.communityVotes.find(
        poll => poll.poll.toString() === data._id.toString(),
      )
        ? true
        : false
    } else {
      return false
    }
  }
  return (
    <>
      <Fade  bottom>
        <Card
          shadow
          className="mb-4"
          style={{ position: 'relative', zIndex: 2, background: theme.palette.accents_1 }}
          align="left">
          <Grid.Container>
            {data.user && <UserContainer user={data.user} />}
            <Grid align="left" xs={11}>
              <Badge className="mr-2">{uppercase(data.type)}</Badge>
              <Badge className="mr-2" style={{ backgroundColor: theme.palette.alert }}>
                {data.category}
              </Badge>
              {voted && (
                <Badge
                  className="mr-2"
                  style={{ backgroundColor: theme.palette.cyanDark }}>
                  Voted
                </Badge>
              )}
              {data.closed && (
                <Badge style={{ backgroundColor: theme.palette.cyanDark }}>
                  <XOctagon size="12" /> Closed
                </Badge>
              )}
            </Grid>
            <Grid justify="flex-end" xs={12}>
              <Text em small>
                <Moment format="MM/DD/YYYY">{data.createdAt}</Moment>
              </Text>
            </Grid>
          </Grid.Container>
          <Spacer y={0.5} />
          {!single && (
            <Avatar.Group className="width100 pointer">
              <Avatar
                onClick={() => historyHandler()}
                src={data.image}
                width="75px"
                height="75px"
                className="avatarMin"
              />
              <Text onClick={() => historyHandler()} className="ml-2 my-4 pt-2" h2>
                {data.name}
              </Text>
            </Avatar.Group>
          )}
          {single && !voted && profile && (
            <VoteForm
              id={data._id}
              fetchPoll={fetchPoll}
              slug={data.slug}
              setVoted={setVoted}
              choices={data.choices}
            />
          )}
          {single && (
            <>
              <Spacer />
              <Spacer />
              <Grid.Container>
                <Grid alignItems="center" justify="center" direction="column" xs={12}>
                  <Text h3 className="text-center subHeader">
                    Total Votes
                  </Text>
                  <Text className="normal subHeader">{countVotes(data.choices)}</Text>
                </Grid>
                <Grid alignItems="center" direction="column" justify="center" xs={12}>
                  <Text h3 className="text-center subHeader">
                    Total Posts
                  </Text>
                  <Text className="normal subHeader">{data.postCount}</Text>
                </Grid>
              </Grid.Container>
              <Spacer />
            </>
          )}
          {single && voted && <GeneralResults data={data.choices} />}
          {single && voted && <AlignmentResults data={data.choices} />}
          {/* <Spacer /> */}
          <Description
            title=""
            content={
              <>
                <ReferenceContainer refs={data.references} />
              </>
            }
          />
          <TagContainer tags={data.tags} />
          <Card.Footer>
            <Grid.Container>
              {!single && (
                <Grid align="left" xs={11}>
                  <Dot style={{ marginRight: '10px', fontSize: '12px' }} type="success">
                    {countVotes(data.choices)} Votes
                  </Dot>
                  <Dot style={{ marginRight: '10px', fontSize: '12px' }} type="warning">
                    {data.postCount} Posts
                  </Dot>
                </Grid>
              )}
              <Grid justify="flex-end" xs={single ? 24 : 12}>
                {profile ? <Favorite type={data.type} data={data} /> : null}
                <CopyLink id={data._id} pollOrPost="poll" />
                <OptionContainer
                  type="poll"
                  profile={profile}
                  user={data.user}
                  pollId={data._id}
                />
              </Grid>
            </Grid.Container>
          </Card.Footer>
        </Card>
      </Fade>
      {!profile && single ? (
        <Card
          shadow
          className="mb-4"
          style={{ position: 'relative', zIndex: 2 }}
          align="left">
          <Text className="text-center" h4>
            Please Sign in to View and Create Posts{' '}
            <Link to="/signin">
              <Button className="ml-2">Sign In</Button>
            </Link>
          </Text>
        </Card>
      ) : !voted && single && profile ? (
        <Card
          shadow
          className="mb-4"
          style={{ position: 'relative', zIndex: 2 }}
          align="left">
          <Text className="text-center" h4>
            Please Vote to View and Create Posts
          </Text>
        </Card>
      ) : profile && single && voted ? (
        <Posts
          single={single}
          length={data.posts.length}
          type={data.type}
          id={data._id}
        />
      ) : null}
    </>
  )
}
export default PollContainer
