import React, { useState } from 'react'
import { Button } from '@geist-ui/react'

const BackToTop = () => {
  const [isHidden, setIsHidden] = useState(true)
  const showOnPx = 100

  const goToTop = () => {
    document.body.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const scrollContainer = () => {
    return document.documentElement || document.body
  }

  document.addEventListener('scroll', () => {
    if (scrollContainer().scrollTop > showOnPx) {
      setIsHidden(false)
    } else {
      setIsHidden(true)
    }
  })

  return (
    <Button
      auto
      onClick={() => goToTop()}
      className={`back-to-top ${isHidden ? 'hidden' : ''}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="back-to-top-icon"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M7 11l5-5m0 0l5 5m-5-5v12"
        />
      </svg>
    </Button>
  )
}
export default BackToTop
