import React from "react";
import { Grid, Text} from "@geist-ui/react";

const EmptyState = () => {
  return (
    <Grid className="text-center mt-4">
        <Text h3 className="mb-4">No Results Found</Text>
    </Grid>
  );
};

export { EmptyState };
