import React from 'react'
import { Grid, Text } from '@geist-ui/react'

const Loader = () => {
  return (
    <Grid.Container
      style={{ height: '100vh' }}
      alignItems="center"
      className="mt-4">
      <Grid justify="center" xs={24}>
        <Text className="subHeader" h2>
          .:: Loading ::.
        </Text>  
      </Grid>
    </Grid.Container>
  )
}

export default Loader
