import React, { useState, useEffect, useContext } from 'react'
import Layout from './Layout/Layout'
import { Card, Grid, User, Tabs, Text, Button } from '@geist-ui/react'
import { authContext } from '../Contexts/authProvider'
import Loader from '../Components/Common/Loader'
import { userService } from '../Services'
import { Link } from 'react-router-dom'
import EditUserForm from '../Components/Forms/EditUserForm'
import InfiniteScrollContainer from '../Components/Containers/InfiniteScrollContainer'
import PollContainer from '../Components/Containers/PollContainer'
import PostContainer from '../Components/Containers/PostContainer'
import UserContainer from '../Components/Containers/UserContainer'
import Follow from '../Components/Follow/Follow'
import HelmetContainer from '../Components/Containers/HelmetContainer'
import { useTheme } from '@geist-ui/react'

const Profile = props => {
  const { palette } = useTheme()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [openedTab, setOpenedTab] = useState('polls')
  const [pageControl, setPageControl] = useState({
    page: 1,
    results: [],
    totalHits: 0,
  })
  let { match } = props

  const { profile } = useContext(authContext)

  const asyncCall = () => {
    return userService.getUser(match.params.id)
  }

  const tabAsyncCall = () => {
    if (openedTab === 'polls') {
      return userService.getUserPolls(match.params.id, pageControl.page)
    } else if (openedTab === 'officialFavorites') {
      return userService.getOfficialFavorites(match.params.id, pageControl.page)
    } else if (openedTab === 'communityFavorites') {
      return userService.getCommunityFavorites(match.params.id, pageControl.page)
    } else if (openedTab === 'posts') {
      return userService.getUserPosts(match.params.id, pageControl.page)
    } else if (openedTab === 'followers') {
      return userService.followers(match.params.id, pageControl.page)
    } else if (openedTab === 'following') {
      return userService.following(match.params.id, pageControl.page)
    }
  }

  const handleSuccess = response => {
    setUser(response)
    setLoading(false)
  }

  const handleError = error => {
    setError(error)
    setLoading(false)
  }

  function fetchUser() {
    return asyncCall().then(handleSuccess).catch(handleError)
  }

  const handleChange = e => {
    setPageControl({
      page: 1,
      results: [],
      totalHits: 0,
    })
    setOpenedTab(e)
  }

  function updateUser(values) {
    setUser(values)
  }

  useEffect(() => {
    fetchUser()
    return () => {
      setLoading(true)
      setOpenedTab('polls')
      setPageControl({
        page: 1,
        results: [],
        totalHits: 0,
      })
    }
  }, [match])

  if (loading) return <Loader />

  return (
    <Layout>
      {user ? (
        <>
          <HelmetContainer title={user.displayName} />
          {profile._id === user._id && (
            <>
              <Grid align="center" md={0} sm={24} xs={24}>
                <Card shadow style={{ position: 'relative', zIndex: 2, background: palette.accents_1 }} align="left">
                  <Text className=" text-center mb-4 subHeader">Edit Profile</Text>
                  <EditUserForm updateUser={updateUser} user={user} />
                </Card>
              </Grid>
              <Grid align="center" md={24} sm={0} xs={0}>
                <Card
                  className="mt-4"
                  shadow
                  style={{ position: 'relative', zIndex: 2, background: palette.accents_1 }}
                  align="left">
                  <Text className=" text-center mb-4 subHeader">
                    .:: Edit Profile ::.
                  </Text>
                  <EditUserForm updateUser={updateUser} user={user} />
                </Card>
              </Grid>
            </>
          )}
          <Card
            className="mt-4"
            shadow
            style={{ position: 'relative', zIndex: 2, background: palette.accents_1 }}
            align="left">
            <Grid.Container>
              <Grid direction="column" md={24} sm={0} xs={0}>
                <Grid align="left" mt={0.5} xs={24}>
                  <User
                    className="profileImg"
                    size="large"
                    src={user.profileImage}
                    name={user.displayName}>
                    {user.state} | {user.country} | {user.politicalAlignment}
                  </User>
                </Grid>
                <Grid xs={24}>
                  <Text>{user.bio}</Text>
                </Grid>
                {profile._id !== user._id && (
                  <Grid align="flex-end" mt={0.5} xs={24}>
                    <Follow data={user} />
                  </Grid>
                )}
              </Grid>
              <Grid direction="column" md={0} sm={24} xs={24}>
                <Grid align="left" mt={0.5} xs={24}>
                  <User
                    className="profileImg"
                    size="large"
                    src={user.profileImage}
                    name={user.displayName}></User>
                </Grid>
                <Grid direction="column" xs={24}>
                  <Grid xs={24}>
                    <Text className="mt-3" h6>
                      {user.state} | {user.country} | {user.politicalAlignment}
                    </Text>
                  </Grid>
                  <Grid xs={24}>
                    <Text className="mt-0 mb-2">{user.bio}</Text>
                  </Grid>
                </Grid>
                {profile._id !== user._id && (
                  <Grid align="flex-end" mt={0.5} xs={24}>
                    <Follow data={user} />
                  </Grid>
                )}
              </Grid>
            </Grid.Container>
            <Tabs onChange={e => handleChange(e)} className="mt-4" initialValue="polls">
              <Tabs.Item label={`Polls (${user.communityPolls.length})`} value="polls">
                <InfiniteScrollContainer
                  asyncCall={tabAsyncCall}
                  pageControl={pageControl}
                  setPageControl={setPageControl}>
                  {pageControl.results.map(result => {
                    return (
                      <PollContainer
                        type={result.type}
                        key={result._id}
                        profile={user}
                        data={result}
                      />
                    )
                  })}
                </InfiniteScrollContainer>
              </Tabs.Item>
              {profile._id === user._id && (
                <>
                  <Tabs.Item
                    label={`Official Favorites (${user.officialPollfavorites.length})`}
                    value="officialFavorites">
                    <InfiniteScrollContainer
                      asyncCall={tabAsyncCall}
                      pageControl={pageControl}
                      setPageControl={setPageControl}>
                      {pageControl.results.map(result => {
                        return (
                          <PollContainer
                            type={result.type}
                            key={result._id}
                            profile={user}
                            data={result}
                          />
                        )
                      })}
                    </InfiniteScrollContainer>
                  </Tabs.Item>
                  <Tabs.Item
                    label={`Community Favorites (${user.communityPollfavorites.length})`}
                    value="communityFavorites">
                    <InfiniteScrollContainer
                      asyncCall={tabAsyncCall}
                      pageControl={pageControl}
                      setPageControl={setPageControl}>
                      {pageControl.results.map(result => {
                        return (
                          <PollContainer
                            type={result.type}
                            key={result._id}
                            profile={user}
                            data={result}
                          />
                        )
                      })}
                    </InfiniteScrollContainer>
                  </Tabs.Item>
                </>
              )}
              <Tabs.Item label={`Posts (${user.posts.length})`} value="posts">
                <InfiniteScrollContainer
                  asyncCall={tabAsyncCall}
                  pageControl={pageControl}
                  setPageControl={setPageControl}>
                  {pageControl.results.map(result => {
                    return (
                      <PostContainer noOp key={result._id} profile={user} data={result} />
                    )
                  })}
                </InfiniteScrollContainer>{' '}
              </Tabs.Item>
              <Tabs.Item label={`Followers (${user.followers.length})`} value="followers">
                <InfiniteScrollContainer
                  asyncCall={tabAsyncCall}
                  pageControl={pageControl}
                  setPageControl={setPageControl}>
                  {pageControl.results.map(result => {
                    return <UserContainer key={result._id} user={result} />
                  })}
                </InfiniteScrollContainer>{' '}
              </Tabs.Item>
              <Tabs.Item label={`Following (${user.following.length})`} value="following">
                <InfiniteScrollContainer
                  asyncCall={tabAsyncCall}
                  pageControl={pageControl}
                  setPageControl={setPageControl}>
                  {pageControl.results.map(result => {
                    return <UserContainer key={result._id} user={result} />
                  })}
                </InfiniteScrollContainer>
              </Tabs.Item>
            </Tabs>
          </Card>
        </>
      ) : (
        <Card
          className="mt-4"
          shadow
          style={{ position: 'relative', textAlign: 'center', zIndex: 2 }}
          align="left">
          <Text h3>{error}</Text>
          <Link to="/">
            <Button>Return home</Button>
          </Link>
        </Card>
      )}
    </Layout>
  )
}

export default Profile
