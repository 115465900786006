import React, { useState, useContext } from 'react'
import { Text, Input, Button, Spacer } from '@geist-ui/react'
import { formContext } from '../../Contexts/FormProvider'
import { Field } from 'formik'
import Toast from '../Common/Toast'

function FormTags(props) {
  const [tagValue, setTagValue] = useState(null)
  const { label, name, placeholder, ...rest } = props
  const { tags, addTags, removeTags } = useContext(formContext)

  function handleClick(form, name) {
    if (!tagValue) {
      return false
    } else if (tagValue.match(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')) {
      Toast('No Special characters in a tag!', 'error-toast')
      return false
    } else if (tagValue.length < 2) {
      Toast('Tag cannot be shorter than 2 characters!', 'error-toast')
      return false
    } else if (tagValue.length > 20) {
      Toast('Tag cannot be longer than 20 characters!', 'error-toast')
      return false
    } else {
      let newTag = { target: { value: tagValue } }    
      addTags(newTag)
      form.setFieldValue(name, '')
      return false
    }
  }

  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <div isInvalid={form.errors[name] && form.touched[name]}>
            <div className="tags-input">
              <ul id="tags">
                {tags.map((tag, index) => (
                  <li key={index} className="tag">
                    <span className="tag-title">{tag}</span>
                    <span className="tag-close-icon" onClick={() => removeTags(index)}>
                      x
                    </span>
                  </li>
                ))}
              </ul>
              <Input
                id={name}
                placeholder={placeholder}
                {...rest}
                {...field}
                width="100%"
                onKeyUp={event => {
                  setTagValue(event.target.value)
                }}>
                {label}
              </Input>
              <Spacer h={1} />
              <Button
                auto
                type="success"
                scale={2 / 3}
                onClick={e => {
                  e.preventDefault()
                  handleClick(form, name)
                }}>
                Add
              </Button>
            </div>
            <Text>{form.errors[name]}</Text>
          </div>
        )
      }}
    </Field>
  )
}

export default FormTags
