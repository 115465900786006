import React from 'react'
import { Link, Note } from '@geist-ui/react'
import { useTheme } from '@geist-ui/react'

const ReferenceContainer = ({ refs }) => {
  const { palette } = useTheme()

  return (
    <>
      {refs.map(r => (
        <Note
          key={r._id}
          style={{ background: palette.accents_1 }}
          className="mb-1"
          label={r.source}>
          <Link href={r.url} icon style={{ marginRight: '10px' }}>
            {r.title}
          </Link>
        </Note>
      ))}
    </>
  )
}

export default ReferenceContainer
