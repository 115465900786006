import React, { useContext, useState } from 'react'
import { Text, Button, Grid } from '@geist-ui/react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { FormikControl } from '../../Reducers'
import GoogleForm from './GoogleForm'
import FacebookForm from './FacebookForm'
import Toast from '../Common/Toast'
import { authContext } from '../../Contexts/authProvider'
import { authService } from '../../Services'
import HelmetContainer from '../Containers/HelmetContainer'

const RegisterForm = () => {
  const [submitting, setSubmitting] = useState(false)
  let history = useHistory()
  const { profile } = useContext(authContext)

  if (profile) {
    history.push('/')
  }

  const initialValues = {
    email: '',
    displayName: '',
    password: '',
    password2: '',
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .min(5, 'Must be atleast 5 characters')
      .max(50, 'Must be at the most 50 characters')
      .email('Invalid email format')
      .required('Required'),
    password: Yup.string()
      .min(6, 'Must be atleast 6 characters')
      .max(50, 'Must be at the most 50 characters')
      .required('Required'),
    displayName: Yup.string()
      .min(3, 'Must be atleast 3 characters')
      .max(100, 'Must be at the most 100 characters'),
    password2: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
  })

  const onSubmit = values => {
    setSubmitting(true)
    authService
      .register(values)
      .then(() => {
        setSubmitting(false)
        Toast('An email has been sent to verify your new account')
        history.push('/signin')
      })
      .catch(res => {
        setSubmitting(false)
        Toast(res, 'error-toast')
      })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}>
      {formik => {
        return (
          <Grid.Container>
            <HelmetContainer title="Sign Up" />
            {/* <RegistrationAlert /> */}
            <Grid direction="column" className="px-4" md={12} xs={24}>
              <Text className="bold mt-4" h2>
                Join <span className="blue">Poll</span>Politic!
              </Text>
              {/* <Grid direction="row" xs={24}> */}
                <GoogleForm />
                {/* <FacebookForm /> */}
                {/* <TwitterForm /> */}
              {/* </Grid>{' '} */}
              <Form>
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="email"
                  placeholder="Email"
                />
                <FormikControl
                  control="input"
                  type="text"
                  label="Display Name (Optional)"
                  name="displayName"
                  placeholder="Display Name (Optional)"
                />
                <FormikControl
                  control="password"
                  label="Password"
                  name="password"
                  placeholder="Password"
                />
                <FormikControl
                  control="password"
                  label="Confirm Password"
                  name="password2"
                  placeholder="Confirm Password"
                />
                <Button
                  loading={submitting ? true : false}
                  className="mt-2"
                  onClick={() => formik.submitForm()}
                  type="secondary"
                  shadow>
                  Create Account
                </Button>
              </Form>
              <Text className="pt-3" h6>
                By signing up I agree to the{' '}
                <Link className="link" to="/termsofservice">
                  Terms of Service
                </Link>{' '}
                and{' '}
                <Link className="link" to="/privacypolicy">
                  Privacy Policy
                </Link>
              </Text>
              <Text className="pb-4" h6>
                Already Have An Account?{' '}
                <Link className="link" to="/signin">
                  Sign In
                </Link>
              </Text>
            </Grid>
            <Grid className="signInBg" direction="column" md={12} xs={24}></Grid>
          </Grid.Container>
        )
      }}
    </Formik>
  )
}
export default RegisterForm
