import React, { useContext } from 'react'
import { GoogleLogin } from 'react-google-login'
import { Button, Text } from '@geist-ui/react'
import { authService } from '../../Services'
import { useHistory } from 'react-router-dom'
import { authContext } from '../../Contexts/authProvider'
import Toast from '../Common/Toast'

const clientId =
  '473586332086-ucd22tl3a922g6sb3t7fcl0dhlvd7j3b.apps.googleusercontent.com'

function GoogleForm() {
  let history = useHistory()
  const { setAuthData } = useContext(authContext)

  const onSuccess = res => {
    authService
      .google(res.tokenId)
      .then(res => {
        setAuthData(res)
        Toast('Welcome back to PollPoltiic')
        history.push('/')
      })
      .catch(res => {
        Toast(res,'info')
      })
  }
  const onFailure = res => {
    console.log(res.error)
  }

  return (
    <GoogleLogin
      clientId={clientId}
      render={renderProps => (
        <Button
          className="mb-4 mt-2"
          type="secondary"
          ghost
          auto
          onClick={renderProps.onClick}>
          <Text className="bold">G</Text>
        </Button>
      )}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy={'single_host_origin'}
      isSignedIn={true}
      buttonText="Login"
    />
  )
}

export default GoogleForm
