import React, { useContext } from 'react'
import { Grid, Text, Button, User, Spinner } from '@geist-ui/react'
import { Link, useHistory } from 'react-router-dom'
import { authContext } from '../../Contexts/authProvider'
import MobileNav from './MobileNav'

const Navbar = () => {
  const history = useHistory()
  const { profile, loading } = useContext(authContext)

  return (
    <>
      <Grid lg={24} md={0} sm={0} xs={0}>
        <Grid.Container
          style={{ paddingTop: '10px', zIndex: '9', position: 'absolute'}}
          gap={2}
          justify="space-evenly">
          <div class="triangle-before"></div>
          <Grid xs={12} md={8}>
            <Text className="pointer" onClick={() => history.push('/')} h4 b>
              <span className="blue">POLL</span>POLITIC
            </Text>
          </Grid>
          <Grid className="mainNav" justify="space-evenly" xs={12} md={12}>
            <Link to="/">
              <Text style={{ marginTop: 0 }}>Home</Text>
            </Link>
            <Link to="/about">
              <Text style={{ marginTop: 0 }}>About</Text>
            </Link>
            <Link to="/officialpolls">
              <Text style={{ marginTop: 0 }}>Official Polls</Text>
            </Link>
            <Link to="/communitypolls">
              <Text style={{ marginTop: 0 }}>Community Polls</Text>
            </Link>

            {loading ? (
              <Spinner />
            ) : (
              <>
                {profile ? (
                  <Link className="marginUser" to={`/profile/${profile._id}`}>
                    <User className="navUser" src={profile.profileImage} name={profile.displayName} />
                  </Link>
                ) : (
                  <Link className="marginIgnore" to="/signin">
                    <Button type="success" scale={0.7}>Sign In</Button>
                  </Link>
                )}
              </>
            )}
          </Grid>
          <div class="triangle-end"></div>
        </Grid.Container>
      </Grid>
      <Grid lg={0} md={24} sm={24} xs={24}>
        <MobileNav />
      </Grid>
    </>
  )
}

export default Navbar
