import React from 'react';
import FormInput from '../Components/Form/FormInput';
import FormTextarea from '../Components/Form/FormTextarea';
import FormSelect from '../Components/Form/FormSelect';
import FormCheck from '../Components/Form/FormCheck';
import FormRadio from '../Components/Form/FormRadio';
import FormVote from '../Components/Form/FormVote';
import FormFile from '../Components/Form/FormFile';
import FormMulti from '../Components/Form/FormMulti';
import FormTags from '../Components/Form/FormTags';
import FormSearch from '../Components/Form/FormSearch';
import FormPassword from '../Components/Form/FormPassword';

export const FormikControl = (props) => {
    const { control, ...rest } = props;
    switch(control){
        case 'input':
            return <FormInput {...rest}/>
        case 'textarea':
            return <FormTextarea {...rest} />
        case 'select':
            return <FormSelect {...rest}/>
        case 'check':
            return <FormCheck {...rest} />
        case 'radio':
            return <FormRadio {...rest} />
        case 'vote':
            return <FormVote {...rest} />
        case 'image':
            return <FormFile {...rest} />
        case 'search':
            return <FormSearch {...rest} />
        case 'references':
            return <FormMulti inputs={'references'} {...rest} />
        case 'choices':
            return <FormMulti inputs={'choices'} {...rest} />
        case 'tags':
            return <FormTags {...rest} />
        case 'password':
            return <FormPassword {...rest} />
        default:
            return null
    }
}

