import React from 'react'
import { Field } from 'formik'
import { Select, Text } from '@geist-ui/react'

function FormSelect(props) {
  const { label, name, options, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form }) => {
        return (
          <>
            <label htmlFor={name}>{label}</label>
            <div isInvalid={form.errors[name] && form.touched[name]}>
              <Select
                initialValue={field.value}
                label={true}
                id={name}
                {...rest}
                {...field}
                onChange={e => form.setFieldValue(name, e)}>
                {options.map(option => {
                  return (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  )
                })}
              </Select>
              <Text className="error">{form.errors[name]}</Text>
            </div>
          </>
        )
      }}
    </Field>
  )
}

export default FormSelect
