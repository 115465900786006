import React, { createContext, useState } from 'react'

export const formContext = createContext({})

const FormProvider = ({ children }) => {
  const [references, setReferences] = useState([])
  const [choices, setChoices] = useState([])
  const [image, setImage] = useState(null)
  const [tags, setTags] = useState([])

  const removeTags = indexToRemove => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)])
  }

  const addTags = event => {
    if (event.target.value !== '') {
      setTags([...tags, event.target.value])
      event.target.value = ''
    }
  }

  const setChoicesData = data => {
    setChoices(prevArray => [...prevArray, data])
  }

  const setReferencesData = data => {
    setReferences(prevArray => [...prevArray, data])
  }

  const clearReferences = () => {
    setReferences([])
  }

  const clearChoices = () => {
    setChoices([])
  }

  const removeReference = reference => {
    let id = reference.id ? reference.id : reference._id
    setReferences(prevArr =>
      prevArr.filter(e => {
        if (e.id) {
          return e.id !== id
        } else if (e._id) {
          return e._id !== id
        }
        return false
      }),
    )
  }

  const removeChoice = choice => {
    let id = choice.id ? choice.id : choice._id
    setChoices(prevArr =>
      prevArr.filter(e => {
        if (e.id) {
          return e.id !== id
        } else if (e._id) {
          return e._id !== id
        }
        return false
      }),
    )
  }

  return (
    <formContext.Provider
      value={{
        references,
        setReferencesData,
        choices,
        setChoicesData,
        image,
        setImage,
        tags,
        setTags,
        addTags,
        removeTags,
        removeReference,
        clearReferences,
        removeChoice,
        clearChoices,
      }}>
      {children}
    </formContext.Provider>
  )
}

export default FormProvider
