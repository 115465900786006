import React from "react";
import { Helmet } from "react-helmet-async";

const HelmetContainer = (props) => {
  const { data, title } = props;
  return (
    <Helmet>
      <title>{title} - PollPolitic</title>
      <meta property="og:image" content={data?.image ? data.image : "https://imgur.com/eU2lCIO"} />
      <meta property="og:description" content={title}/>
      <meta property="og:title" content={`${title} - PollPolitic`}/>

      <meta property="twitter:title" content={`${title} - PollPolitic`}/>
    </Helmet>
  );
};

export default HelmetContainer;
{/* <Helmet>
<meta property="og:type" content={data.type} />
<meta property="og:url" content={``}/>
<meta property="og:description" content="Offering tour packages for individuals or groups."/>
</Helmet>
 */}
