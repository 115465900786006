import React, { useState, useEffect } from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts'
import { Flex } from '@geist-ui/react'

let renderLabel = function (entry) {
  return entry.name
}

const GeneralResults = ({ data }) => {
  const [chartData, setChartData] = useState([])
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

  useEffect(() => {
    let tempData = data.map(item => {
      return {
        name: item.value,
        value: item.votes,
      }
    })
    setChartData(tempData)
  }, [data])

  return (
    <>
      <ResponsiveContainer width="99%" aspect={window.matchMedia("(min-width: 768px)").matches ? 3 : 1}>
        <PieChart width={800} >
          <Pie
            innerRadius={60}
            outerRadius={80}
            paddingAngle={5}
            dataKey="value"
            data={chartData}
            fill="#8884d8"
            label={renderLabel}
          />
          <Tooltip />
          <Legend />
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default GeneralResults
