import React, { createContext, useState, useEffect } from "react";
import { configService } from "../Services";

export const configContext = createContext({});

const ConfigProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [maintenance, setMaintenance] = useState(false);
  const [registration, setRegistration] = useState(true);
  const [pollCreation, setPollCreation] = useState(true);
  const [postCreation, setPostCreation] = useState(true);
  const [articleCreation, setArticleCreation] = useState(true);
  const [pollVoting, setPollVoting] = useState(true);

  async function getConfig() {
    configService
      .getConfig()
      .then((res) => {
        setMaintenance(res.maintenance);
        setRegistration(res.registration);
        setPollCreation(res.poll_creation);
        setPostCreation(res.post_creation);
        setArticleCreation(res.article_creation);
        setPollVoting(res.poll_voting);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    getConfig();
  }, []);

  return (
    <configContext.Provider
      value={{
        maintenance,
        registration,
        pollCreation,
        postCreation,
        articleCreation,
        pollVoting,
        loading,
      }}
    >
      {children}
    </configContext.Provider>
  );
};

export default ConfigProvider;
