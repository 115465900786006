import React from 'react'
import { Text } from '@geist-ui/react'

const TagContainer = ({ tags }) => {
  return (
    <Text my={0} py={0.5} px={0.5} small blockquote type="secondary">
      {tags.map(t => <span className="tagDiv">{t}</span>)}
    </Text>
  )
}
export default TagContainer
