import React, { useContext, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../Reducers'
import { Button, Modal, Spacer } from '@geist-ui/react'
import { formContext } from '../../Contexts/FormProvider'
import { v4 as uuidv4 } from 'uuid'

function ReferenceForm({ form }) {
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const { setReferencesData } = useContext(formContext)
  const initialValues = {
    id: '',
    title: '',
    url: '',
    source: '',
  }

  const validationSchema = Yup.object({
    title: Yup.string()
      .min(5, 'Must be atleast 5 characters')
      .max(100, 'Must be at the most 100 characters')
      .required('Required'),
    /* eslint-disable */
    url: Yup.string()
      .matches(
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
        'Please enter a valid url',
      )
      .min(5, 'Must be atleast 5 characters')
      .max(300, 'Must be at the most 300 characters')
      .required('Required'),
    /* eslint-enable */
    source: Yup.string()
      .min(2, 'Must be atleast 2 characters')
      .max(25, 'Must be at the most 25 characters')
      .required('Required'),
  })

  const onSubmit = (values, { resetForm }) => {
    values.id = uuidv4();
    setReferencesData(values)
    resetForm({})
    closeHandler()
    form.setFieldValue('references', ['1'])
  }

  const closeHandler = event => {
    setState(false)
  }

  return (
    <>
      <Spacer h={1} />
      <Button type="success" scale={2 / 3} className="mb-2" onClick={handler}>
        Add a Reference
      </Button>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {(formik) => {
          return (
            <Modal visible={state} onClose={closeHandler}>
              <Modal.Title>Add a Reference</Modal.Title>
              <Modal.Content>
                <Form>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Title"
                    name="title"
                    placeholder="Title of the reference"
                  />
                  <FormikControl
                    control="input"
                    type="url"
                    label="Url"
                    name="url"
                    placeholder="Url to the Reference"
                  />
                  <FormikControl
                    control="input"
                    type="text"
                    label="Source"
                    name="source"
                    placeholder="Source of the Reference"
                  />
                </Form>
              </Modal.Content>
              <Modal.Action passive onClick={() => setState(false)}>
                Cancel
              </Modal.Action>
              <Modal.Action onClick={() => formik.submitForm()}>Add</Modal.Action>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}

export default ReferenceForm
