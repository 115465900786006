import React, { useEffect, useState, useContext } from "react";
import { authContext } from "../../Contexts/authProvider";
import { Text } from '@geist-ui/react'
import { Link, useHistory } from "react-router-dom";
import { authService } from "../../Services";
import HelmetContainer from "../Containers/HelmetContainer";

function VerifyForm({ match }) {
  let history = useHistory();
  const [response, setResponse] = useState({
    title: null,
    body: null,
    status: false,
  });
  const { profile } = useContext(authContext);

  if (profile) {
    history.push("/");
  }

  function getToken() {
    let token = match.params.id;
    authService
      .verify(token)
      .then(() => {
        setResponse({ title: "Thank You!", body: "The account has successfully been verified.", status: true });
      })
      .catch((res) => {
        setResponse({ title: "Oops! Error!", body: res.toString() });
      });
  }

  useEffect(() => {
    getToken()
  }, [match])

  return (
    <div className="mb-4 px-4">
      <HelmetContainer title="Verify" />
      <Text className="my-4" h2>
        {response.title}
      </Text>
      <div>
        <Text className="mb-4">{response.body}</Text>
        {response.status ? (
          <Link to="/signin" className="link">
            Click here to go signin
          </Link>
        ) : null}
      </div>
    </div>
  );
}
export default VerifyForm;
