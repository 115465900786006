import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { authContext } from "../Contexts/authProvider";
import Loader from "../Components/Common/Loader";

export const PublicRoute = ({ component: Component, render, ...rest }) => {
  const { loading } = useContext(authContext);
  if (loading) return <Loader />;
  const renderContent = (props) => {
    return typeof render === "function" ? (
      render(props)
    ) : (
      <Component {...props} />
    );
  };

  return <Route {...rest} render={renderContent} />;
};
