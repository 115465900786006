import React, { useContext } from 'react'
import { Field } from 'formik'
import { Text, Button } from '@geist-ui/react'
import { formContext } from '../../Contexts/FormProvider'
import ChoiceForm from '../Forms/ChoiceForm'
import ReferenceForm from '../Forms/ReferenceForm'
import ToolTip from '../Common/ToolTip'
import { XCircle } from '@geist-ui/react-icons'

function FormMulti(props) {
  const { references, removeReference, choices, removeChoice } = useContext(formContext)
  const { label, name, inputs, tooltipBody } = props
  if (inputs === 'references')
    return (
      <Field name={name}>
        {({ field, form }) => {
          return (
            <div isInvalid={form.errors[name] && form.touched[name]}>
              <label htmlFor={name}>
                {label} {tooltipBody ? <ToolTip body={tooltipBody} /> : null}
              </label>
              {references.length ? (
                <>
                  {references.map(reference => (
                    <div className="formMultiContainer">
                      {reference.title}{' '}
                      <Text
                        className="formMultiDelete"
                        onClick={() => {
                          removeReference(reference)
                          if (references.length === 1) {
                            form.setFieldValue(name, [])
                          }
                        }}>
                        <XCircle size={21}/>
                      </Text>
                    </div>
                  ))}
                </>
              ) : null}
              {references.length === 3 ? (
                <Text font="12px">Limit Reached</Text>
              ) : (
                <ReferenceForm form={form} />
              )}
              <Text className="mb-0" font="12px">
                {references.length}/3 References
              </Text>
              <Text className="mt-0 error">{form.errors[name]}</Text>
            </div>
          )
        }}
      </Field>
    )
  if (inputs === 'choices')
    return (
      <Field name={name}>
        {({ field, form }) => {
          return (
            <div isInvalid={form.errors[name] && form.touched[name]}>
              <label htmlFor={name}>
                {label} {tooltipBody ? <ToolTip body={tooltipBody} /> : null}
              </label>
              {choices.length ? (
                <>
                  {choices.map(choice => (
                    <div className="formMultiContainer">
                      {choice.value}{' '}
                      <Text
                        className="formMultiDelete"
                        onClick={() => {
                          removeChoice(choice)
                          if (choices.length === 1) {
                            form.setFieldValue(name, [])
                          } else if (choices.length === 2) {
                            form.setFieldValue(name, ['1'])
                          } else if (choices.length === 3) {
                            form.setFieldValue(name, ['1', '2'])
                          }
                        }}>
                        <XCircle size={21}/>
                      </Text>
                    </div>
                  ))}
                </>
              ) : null}
              {choices.length === 3 ? (
                <Text font="12px">Limit Reached</Text>
              ) : (
                <ChoiceForm form={form} />
              )}
              <Text className="mb-0" font="12px">
                {choices.length}/3 Choices
              </Text>
              <Text className="mt-0 error">{form.errors[name]}</Text>
            </div>
          )
        }}
      </Field>
    )
}

export default FormMulti
