import React from 'react'
import { toast } from 'react-toastify';

const Toast = ( body, type ) => {
  toast(body, {
    position: 'bottom-right',
    className: type,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
  return null;
}

export default Toast
