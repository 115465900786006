import { POLLAXY_API_URL as API_URL } from "../Constants/actionTypes";
import { handleResponse, authHeader } from "../Helpers";
import { ParamControl } from '../Reducers';

export const officialPollService = {
  getOfficialPolls,
  getLatestOfficialPoll,
  getOfficialPollPosts,
  addOfficialPoll,
  updatePoll,
  deletePoll,
  castOfficialVote,
  favoriteOfficialPoll,
  unfavoriteOfficialPoll,
  toggleCloseOfficialPoll,
};


function getOfficialPolls(param, page, q) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
  };
  return fetch(`${API_URL}/polls?page=${page ? page: 1}${param ? `&${ParamControl(param)}` : ''}${q ? `&q=${q}`: ''}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function getLatestOfficialPoll() {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(`${API_URL}/polls/latest`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res
    })
}


function getOfficialPollPosts(pollId, page, param) {
  const requestOptions = {
    method: 'GET',
    credentials: 'include',
  }
  return fetch(
    `${API_URL}/polls/${pollId}/posts?page=${page ? page : 1}${param ? `&${ParamControl(param)}` : ''}`,
    requestOptions,
  )
    .then(handleResponse)
    .then(res => {
      return res
    })
}

function addOfficialPoll(payload) {
  const requestOptions = {
    method: "POST",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/polls`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function updatePoll(id, payload) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/polls/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function deletePoll(id) {
  const requestOptions = {
    method: "DELETE",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/${id}`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function castOfficialVote(id, payload) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload)
  };
  return fetch(`${API_URL}/polls/${id}/vote`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function favoriteOfficialPoll(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/${id}/favorite`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function unfavoriteOfficialPoll(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/${id}/unfavorite`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}

function toggleCloseOfficialPoll(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/polls/${id}/close`, requestOptions)
    .then(handleResponse)
    .then(res => {
      return res;
    });
}
