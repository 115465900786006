import { POLLAXY_API_URL as API_URL } from "../Constants/actionTypes";
import { handleResponse, authHeader } from "../Helpers";

export const uploadService = {
  addImage,
};

function addImage(payload) {
  let image = new FormData();
  payload.map((img) => image.append("images", img.file));

  const requestOptions = {
    credentials: "include",
    method: "POST",
    headers: authHeader(image),
    body: image,
  };
  console.log(requestOptions)
  return fetch(`${API_URL}/upload/`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
