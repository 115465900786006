import React from "react";
import LoginForm from "../Components/Forms/LoginForm";
import RegisterForm from "../Components/Forms/RegisterForm";
import ForgotPasswordForm from "../Components/Forms/ForgotPasswordForm";
import ReportForm from "../Components/Forms/ReportForm";
import ReferenceForm from "../Components/Forms/ReferenceForm";
import NewPasswordForm from "../Components/Forms//NewPasswordForm";
import VerifyForm from "../Components/Forms/VerifyForm";
import PollForm from "../Components/Forms/PollForm";
import PostForm from "../Components/Forms/PostForm";
import EditPollForm from "../Components/Forms/EditPollForm";
import EditPostForm from "../Components/Forms/EditPostForm";
import EditUserForm from "../Components/Forms/EditUserForm";
import DeactivateForm from "../Components/Forms/DeactivateForm";
import LogoutForm from "../Components/Forms/LogoutForm";
import VoteForm from "../Components/Forms/VoteForm";
import SearchForm from "../Components/Forms/SearchForm";

export const FormControl = (props) => {
  const { control, ...rest } = props;
  switch (control) {
    case "login":
      return <LoginForm {...rest} />;
    case "register":
      return <RegisterForm {...rest} />;
    case "resetpassword":
      return <ForgotPasswordForm {...rest} />;
    case "report":
      return <ReportForm {...rest} />;
    case "reference":
      return <ReferenceForm {...rest} />;
    case "share":
      return <ShareForm {...rest} />;
    case "newpassword":
      return <NewPasswordForm {...rest} />;
    case "verify":
      return <VerifyForm {...rest} />;
    case "poll":
      return <PollForm {...rest} />;
    case "post":
      return <PostForm {...rest} />;
    case "editpoll":
      return <EditPollForm {...rest} />;
    case "editpost":
      return <EditPostForm {...rest} />;
    case "edituser":
      return <EditUserForm {...rest} />;
    case "logout":
      return <LogoutForm {...rest} />;
    case "deactivate":
      return <DeactivateForm {...rest} />;
    case "search":
      return <SearchForm {...rest} />;
    case "vote":
      return <VoteForm {...rest} />;
    default:
      return null;
  }
};
