import React from 'react'
import { Share2 } from '@geist-ui/react-icons'
import { Button } from '@geist-ui/react'
import Toast from '../Common/Toast'

const CopyLink = ({ id, pollOrPost }) => {
  return (
    <Button
      auto
      pr={0.4}
      mr={0.3} 
      iconRight={<Share2 />}
      onClick={() => {
        navigator.clipboard.writeText(`https://PollPolitic.com/${pollOrPost}/${id}`)
        Toast('Link Copied!')
      }}
    />
  )
}
export default CopyLink
