import React, { useState, useEffect } from 'react'
import Layout from './Layout/Layout'
import { Text, Grid } from '@geist-ui/react'
import PollContainer from '../Components/Containers/PollContainer'
import Loader from '../Components/Common/Loader'
import { officialPollService } from '../Services'
import HelmetContainer from '../Components/Containers/HelmetContainer'

const Home = () => {
  const [loading, setLoading] = useState(true)
  const [poll, setPoll] = useState(null)
  const [error, setError] = useState(null)

  const asyncCall = () => {
    return officialPollService.getLatestOfficialPoll()
  }

  const handleSuccess = response => {
    setPoll(response)
    setLoading(false)
  }

  const handleError = () => {
    setError("Something Went Wrong. Please Check Your Internet Connection")
    setLoading(false)
  }

  function fetchLatest() {
    return asyncCall().then(handleSuccess).catch(handleError)
  }

  useEffect(() => {
    fetchLatest()
  }, [])

  if (loading) return <Loader />

  return (
    <Layout>
      <HelmetContainer title={'Home'} />
      <Grid md={24} sm={0} xs={0} mt="100px" align="center">
        <Text className="subHeader">WELCOME TO</Text>
        <Text className="mainHeader" h1>
          .:: <span className="blue">POLL</span>POLITIC ::.
        </Text>
        {poll ? (
          <PollContainer single={false} type="official" key={poll._id} data={poll} />
        ) : (
          <Grid className="mt-4 text-center" xs={24}>
            <Text h3>{error}</Text>
          </Grid>
        )}
      </Grid>
      <Grid align="center" md={0} sm={24} xs={24}>
        <Text className="subHeader">WELCOME TO</Text>
        <Text className="mainHeader mb-4" h1>
          <span className="blue">POLL</span>POLITIC
        </Text>
        {poll ? (
          <PollContainer single={false} type="official" key={poll._id} data={poll} />
        ) : (
          <Grid className="mt-4 text-center" xs={24}>
            <Text h3>{error}</Text>
          </Grid>
        )}
      </Grid>
    </Layout>
  )
}

export default Home
