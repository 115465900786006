export function handleResponse(response) {
    return response.text().then(text => {
        let data;
        try {
            data = text && JSON.parse(text);
        } catch (e) {
            data = text;
        }
        if(!response.ok){
            const error = (data && data.message) || (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}