import React, { useState } from 'react'
import { Button, Modal } from '@geist-ui/react'

const Delete = ({ postId, pollId, toDelete, type, setModalState }) => {
  const [state, setState] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const handler = () => setState(true)
  const confirm = () => {
    setSubmitting(true)
    if (type === 'poll') {
      toDelete(pollId);
    } else {
      toDelete(pollId, postId);
    }
    setSubmitting(false);
    setState(false)
    setModalState(false)
  }
  const closeHandler = event => {
    setState(false)
  }
  return (
    <>
      <Button type="error" className="mb-2" onClick={handler}>
        {type === 'poll' ? 'Delete Poll' : 'Delete Post'}
      </Button>
      <Modal visible={state} onClose={closeHandler}>
        <Modal.Title> {type === 'poll' ? 'Delete Poll' : 'Delete Post'}</Modal.Title>
        <Modal.Subtitle>Are you sure you wish to delete?</Modal.Subtitle>
        <Modal.Action passive onClick={() => setState(false)}>
          Cancel
        </Modal.Action>
        <Modal.Action loading={submitting ? true : false} onClick={() => confirm()}>Confirm</Modal.Action>
      </Modal>
    </>
  )
}
export default Delete
