import { POLLAXY_API_URL as API_URL } from "../Constants/actionTypes";
import {
  handleResponse,
  authHeader,
} from "../Helpers";

export const userService = {
  getUsers,
  getUser,
  getProfile,
  updateUser,
  deactivateUser,
  destroyUser,
  banUser,
  unBanUser,
  followUser,
  unfollowUser,
  following,
  followers,
  getOfficialVotes,
  getCommunityVotes,
  getOfficialFavorites,
  getCommunityFavorites,
  getUserPolls,
  getUserPosts,
  toggleDarkMode,
  toggleAuthentic,
};

function getProfile() {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/user/getProfile`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getUsers(page, q) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user?page=${page ? page : 1}${q ? `&q=${q}` : ""}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getUser(id) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/user/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function updateUser(id, payload) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/user/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function deactivateUser(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/user/${id}/deactivate`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function destroyUser(id) {
  const requestOptions = {
    method: "DELETE",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/user/${id}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function banUser(id) {
  let payload = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include",
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/user/ban`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function unBanUser(id) {
  let payload = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include",
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/user/unban`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function followUser(id) {
  let payload = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include",
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/user/${id}/follow`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function unfollowUser(id) {
  let payload = { id: id };
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    credentials: "include",
    body: JSON.stringify(payload),
  };
  return fetch(`${API_URL}/user/${id}/unfollow`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function following(id, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(
    `${API_URL}/user/${id}/following?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function followers(id, page) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(
    `${API_URL}/user/${id}/followers?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getOfficialVotes(id, page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user/${id}/officialvotes?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getCommunityVotes(id, page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user/${id}/communityvotes?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getOfficialFavorites(id, page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user/${id}/officialfavorites?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getCommunityFavorites(id, page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user/${id}/communityfavorites?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getUserPolls(id, page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user/${id}/communitypolls?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function getUserPosts(id, page) {
  const requestOptions = {
    method: "GET",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(
    `${API_URL}/user/${id}/posts?page=${page ? page : 1}`,
    requestOptions
  )
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function toggleDarkMode() {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/user/toggledarkmode`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}

function toggleAuthentic(id) {
  const requestOptions = {
    method: "PUT",
    credentials: "include",
    headers: authHeader(),
  };
  return fetch(`${API_URL}/user/${id}/authentic`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res;
    });
}
