import React, { useContext, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormikControl } from '../../Reducers'
import { Button, Modal, Spacer } from '@geist-ui/react'
import { formContext } from '../../Contexts/FormProvider'
import { v4 as uuidv4 } from 'uuid'

function ReferenceForm({ form }) {
  const [state, setState] = useState(false)
  const handler = () => setState(true)
  const { setChoicesData, choices } = useContext(formContext)
  const initialValues = {
    id: '',
    value: '',
  }

  const validationSchema = Yup.object({
    value: Yup.string()
      .min(2, 'Must be atleast 2 characters')
      .max(10, 'Must be at the most 10 characters')
      .required('Required'),
  })

  const onSubmit = (values, { resetForm }) => {
    if (choices.length >= 1) {
      form.setFieldValue('choices', ['1', '2'])
    }
    values.id = uuidv4()
    setChoicesData(values)
    resetForm({})
    closeHandler()
  }

  const closeHandler = event => {
    setState(false)
  }

  return (
    <>
      <Spacer h={1} />
      <Button type="success" scale={2 / 3} className="mb-2" onClick={handler}>
        Add a Choice
      </Button>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}>
        {formik => {
          return (
            <Modal visible={state} onClose={closeHandler}>
              <Modal.Title>Add a Choice</Modal.Title>
              <Modal.Content>
                <Form>
                  <FormikControl
                    control="input"
                    type="text"
                    label="Name of Choice"
                    name="value"
                    placeholder="Name of the Choice"
                  />
                </Form>
              </Modal.Content>
              <Modal.Action passive onClick={() => setState(false)}>
                Cancel
              </Modal.Action>
              <Modal.Action onClick={() => formik.submitForm()}>Add</Modal.Action>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}

export default ReferenceForm
